import {Component, HostBinding, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { NavigationService } from './global/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'easy-resume';
  logoClass = false;
  shouldNavigationDisplay;
  shouldMobileNavigationDisplay ;
  constructor(private readonly router: Router, private readonly navigationService: NavigationService) {

  }
  @HostBinding('style.width.px')

  ngOnInit() {
    this.navigationService.$navigation.subscribe((route) => {
      this.logoClass = route.urlAfterRedirects !== '/home' && route.urlAfterRedirects !== '/blog';
    });

    if (window.innerWidth < 800) {
      this.shouldNavigationDisplay = false;
      this.shouldMobileNavigationDisplay = true;
    } else {
      this.shouldNavigationDisplay = true;
      this.shouldMobileNavigationDisplay = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 800) {
      this.shouldNavigationDisplay = false;
      this.shouldMobileNavigationDisplay = true;
    } else {
      this.shouldNavigationDisplay = true;
      this.shouldMobileNavigationDisplay = false;

    }
  }
  toggleHamburgerMenu() {
    this.shouldNavigationDisplay = !this.shouldNavigationDisplay;
  }


  goHome() {
    this.router.navigate(['/home'])
  }

  goToBlog() {
    this.router.navigate(['/blog'])
  }
  goToFaq() {
    this.router.navigate(['/faq'])
  }
  getStarted() {
    this.router.navigate(['/pii'])

  }
}

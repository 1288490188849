import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumePreviewComponent } from './resume-preview.component';
import { ResumeOneComponent } from './resume-one/resume-one.component';
import { ResumeTwoComponent } from './resume-two/resume-two.component';
import { ResumeThreeComponent } from './resume-three/resume-three.component';
import { ResumeFourComponent } from './resume-four/resume-four.component';
import { ResumeFiveComponent } from './resume-five/resume-five.component';
import { ResumeSixComponent } from './resume-six/resume-six.component';
import { MaterialModule } from 'src/app/material.module';
import { ResumeSevenComponent } from './resume-seven/resume-seven.component';
import { ResumeEightComponent } from './resume-eight/resume-eight.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';



@NgModule({
  declarations: [
    ResumePreviewComponent,
    ResumeOneComponent,
    ResumeTwoComponent,
    ResumeThreeComponent,
    ResumeFourComponent,
    ResumeFiveComponent,
    ResumeSixComponent,
    ResumeSevenComponent,
    ResumeEightComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ResumeOneComponent,
    ResumeTwoComponent,
    ResumeThreeComponent,
    ResumeFourComponent,
    ResumeFiveComponent,
    ResumeSixComponent,
    ResumeSevenComponent,
    ResumeEightComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ]
})
export class ResumePreviewModule { }

<div style="display: flex; flex-direction: column; max-width: 1200px; width: 80%; margin: auto;"  class="blog-wrapper">
    <h1 class="header-text">{{blog.blogTitle}}</h1>
  
    <div *ngFor="let blog of blog.paragraphs; index as i">
      <section>
        <h2 class="blog-title">{{blog.title}}</h2>
        <p class="blog-content">{{blog.paragraph}}</p>
      </section>
    </div> 
  </div>
  
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CssHelperService {
  private readonly editResume: Subject<boolean> = new Subject();
  $editResume = this.editResume.asObservable();

  setEditStatusToTrue() {
    this.editResume.next(true);
  }

  setEditStatusToFalse() {
    this.editResume.next(false);
  }
  constructor() { }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
import { Subscription } from 'rxjs';
import { ResumeData } from 'src/app/interfaces/resume-detail.interface';
import { GenerateResumesService } from 'src/app/services/generate-resumes.service';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';

@Component({
  selector: 'app-pii',
  templateUrl: './pii.component.html',
  styleUrls: ['./pii.component.scss', '../../../assets/scss/forms.scss']
})
export class PiiComponent implements OnInit {

  constructor(private readonly fb: FormBuilder, 
    private router: Router, 
    private readonly resumeDistributorService: ResumeDistributorService,
    private activatedRoute: ActivatedRoute,
    private readonly resumeGenerator: GenerateResumesService) { }

  hasAboutFormBeenTouched: boolean = false;
  shouldAboutFromDisplay: boolean = false;
  shouldReturnBackButtonDisplay: boolean = false;
  isCurrentActionEdit: boolean = false;
  activatedRouteSubscription: Subscription;
  shouldErrorPopupShow: boolean = false;
  shouldEmailScoreShow:boolean = false;
  shouldSpinnerShow: boolean = false;
  emailScore: number = 0;
  emailSuggestion: string = "";
  previousAnalyzedEmail: string = "";

  resumeForm: FormGroup = this.fb.group({
    firstNameControl: this.fb.control('', [Validators.required]),
    lastNameControl: this.fb.control('', [Validators.required]),
    emailFormControl: this.fb.control('', [Validators.required]),
    phoneNumberControl: this.fb.control('', [Validators.required])
  });

  ngOnInit(): void {
    window.scrollTo(0,0);
    const resumeInfo = this.resumeDistributorService.getResumeDataFromLocalStorage();
    if(resumeInfo) {
      this.resumeForm.controls.firstNameControl.setValue(resumeInfo.firstName || '');
      this.resumeForm.controls.lastNameControl.setValue(resumeInfo.lastName || '');
      this.resumeForm.controls.emailFormControl.setValue(resumeInfo.email || '');
      this.resumeForm.controls.phoneNumberControl.setValue(resumeInfo.phoneNumber || '');
    }

    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.isCurrentActionEdit  = params?.action==='edit'? true : false;
    });
  }

  checkForErrors() {
    let vars = this.resumeForm.controls;
    let errors = [];
    for (const [key, value] of Object.entries(vars)) {
      if(value.errors) {
        errors.push(value.errors)
      }
    }

    this.shouldErrorPopupShow = errors.length > 0 ? true: false; 
  }
  goNext() {
    this.checkForErrors();
    if(!this.shouldErrorPopupShow) {
      if(this.isCurrentActionEdit) {
        this.router.navigate(['/preview'])
      } else {
        this.router.navigate(
          ['/progress'], {queryParams: {completed: 25}});
      }
    }

  }

  ngOnDestroy() {
    this.resumeDistributorService.addPii(this.resumeForm)
    this.activatedRouteSubscription.unsubscribe();
  }

  getText() {
    return this.isCurrentActionEdit?'Done':'Next'
  }


  scoreEmail() {
    if(this.resumeForm.controls.emailFormControl.value !== this.previousAnalyzedEmail) {
      this.shouldSpinnerShow = true;
      this.shouldEmailScoreShow = false;
      this.resumeGenerator.scoreEmail(this.resumeForm.controls.emailFormControl.value).subscribe((res: any) => {

        
       if(res && res?.status == 200) {
          this.previousAnalyzedEmail = this.resumeForm.controls.emailFormControl.value;
          console.log(res)
          let emailTest = res.text.replace(/\n/g, ' ').replace(".", "").split(" ");
          console.log(emailTest)
          const number = emailTest[0];
          emailTest.shift();
          emailTest.shift();
          emailTest.shift();
          const suggestion = emailTest
          this.emailScore = Number(number)
          this.emailSuggestion = suggestion.join(" ")
          this.shouldEmailScoreShow = true;
          this.shouldSpinnerShow = false;
        }
      }, err => {
        this.emailScore = 0;
        this.emailSuggestion = 'Oops.. error getting your email score';
        this.shouldEmailScoreShow = true;
        this.shouldSpinnerShow = false;
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-progress-marker',
  templateUrl: './progress-marker.component.html',
  styleUrls: ['./progress-marker.component.scss']
})
export class ProgressMarkerComponent implements OnInit {
  percentCompleted: string;

  constructor(private route: ActivatedRoute, private readonly router: Router) { }
  completed: string;
  ngOnInit(): void {
    this.percentCompleted = this.route.snapshot.queryParamMap.get('completed');
  }

  goBack() {
    if(this.percentCompleted === '25') {
      this.router.navigate(['/pii'])
    } else if(this.percentCompleted === '50') {
      this.router.navigate(['/work'])
    } else {
      this.router.navigate(['/school'])
    }
  }
  goNext() {
    if(this.percentCompleted === '25') {
      this.router.navigate(['/work'])
    } else if(this.percentCompleted === '50') {
      this.router.navigate(['/school'])
    } else {
      this.router.navigate(['/skills'])
    }
  }
}

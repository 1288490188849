<header style="display: flex; ">
	<section class="logo-container" [ngClass]="{'logo-not-home' : logoClass}" (click)="goHome()">
	  <img src='../../../assets/logo.svg' class="logo">
	  <span class="logo-text">rezumaki</span>
	</section>

  <img src="assets/pictures/hamburger.svg" height="50px" class="hamburger-icon" (click)="toggleHamburgerMenu()">
  <nav *ngIf="shouldNavigationDisplay && !shouldMobileNavigationDisplay" [ngClass]="{'header-not-home' : logoClass, 'nav-items': !logoClass}" >
    <a class="action-text" href="mailto:support@rezumaki.com" style="text-decoration: none">E-Mail Us</a>
    <p class="action-text" (click)="goToBlog()" >Blog</p>
    <p class="action-text">About</p>
    <p class="action-text" (click)="goToFaq()">FAQ</p>   

    <button class="action-button" (click)="getStarted()" >Build Your Resume</button>
  </nav>

</header>

<nav *ngIf="shouldNavigationDisplay && shouldMobileNavigationDisplay" [ngClass]="{'header-not-home' : logoClass, 'nav-items': !logoClass}" >
  <a class="action-text" href="mailto:support@rezumaki.com" style="text-decoration: none">E-Mail Us</a>
  <p class="action-text" (click)="goToBlog()" >Blog</p>
  <p class="action-text">About</p>
  <a class="action-text" href="#faq">FAQ</a>
  <button class="action-button" (click)="getStarted()" >Build Your Resume</button>
</nav>


<app-global-marker></app-global-marker>
<router-outlet></router-outlet>

<div class="policy-terms">
	<span class="policy-info">See our <a href='/privacy'>privacy policy </a></span>
	<span class="terms-info">and <a href='/terms' >terms of service</a></span>
</div>
<div class="policy-holder"></div>

<div style="display: flex; flex-direction: column; align-items: center; background: rgba(0, 0, 0, 0.5);
margin-top: 20px; min-height: 600px;">

<div style="display: flex; width: 90%; align-items: baseline; padding-left: 10%;">
    <span class="header">Success</span>
</div>

    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 10%;">
        <h1 *ngIf="shouldSpinnerShow" style="color:white"><span id="spin"></span></h1>

        <a href="{{downloadUrl}}" #href class="download-button">
            <div *ngIf="shouldSpinnerShow" class="spinner"></div>
            <div *ngIf="!shouldSpinnerShow" class="download-text">Download</div>
        </a>
    </div>
</div>
<div style="max-height: 200px; max-width: 300px;">
<div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
      <div class="header">
        <span class="name default-left-padding">{{resumeData.firstName}} {{resumeData.lastName}}</span>
        <hr>
        <span class="contact-info">{{resumeData.email}} | {{formatPhoneNumberFromString(resumeData.phoneNumber)}}</span>
      </div>    

      <p class="section-header">Skills</p>
      <div class="skill-wrapper " *ngFor="let skill of resumeData.skills; let i = index;">
        <p class="skill"> &bull; <span class="font"  style="padding-left: 10px;">{{skill}}</span></p>
    </div>

    <p class="section-header">Work Experience</p>
    <div *ngFor="let job of resumeData.jobs;">
      <div class="job-wrapper">
        <div style="display: flex; padding-top: .9rem;">
          <p> &bull; </p>
            <div class="job">
                <span class="job-details" style="padding-left: 10px;"> {{job.companyName}} | {{job.position}} | {{job.startDate}} - {{job.endDate}}</span>
                <span class="job-description" style="padding-left: 10px;">{{job.description}}</span>
            </div>
        </div>
      </div>
    </div>

    <p class="section-header">Education</p>
    <div *ngFor="let school of resumeData.school;">
      <div class="school-wrapper">
        &bull;
        <div class="font"  style="padding-left: 10px;">
          {{school.major}} | {{school.schoolName}} | {{school.startYear}} - {{school.endYear}}<br>
        </div>
      </div>
    </div>

  </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { concatAll } from 'rxjs';
import { GenerateResumesService } from 'src/app/services/generate-resumes.service';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss']
})
export class SuccessPageComponent implements OnInit {

  constructor(private readonly generateResume: GenerateResumesService,
    private readonly resumeDistributorService: ResumeDistributorService,
    private route: ActivatedRoute) { }
  shouldSpinnerShow: Boolean = false;
  downloadUrl = "";
  resumeAvailableForDownload = [1,2,3,4,5,6,7]
  @ViewChild('href') downloadButton; 

  ngOnInit(): void {

    this.generateResume.$rezhelpBackend.subscribe(res => {
      this.shouldSpinnerShow = false;    
                
    
      this.downloadUrl = res.location;
  
      // This is the name of our downloaded file
      this.downloadButton.download = `Resume`;
    })
    this.download()
  }

  download() {
    let resInfo = this.resumeDistributorService.getResumeDataFromLocalStorage();
      if(this.resumeAvailableForDownload.includes(resInfo.resumeNumber)) {
        //generates PDF
        this.shouldSpinnerShow = true;    
        this.generateResume.callBackend(resInfo)
        } 
  }

}

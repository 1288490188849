import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import { Subscription } from 'rxjs';
import { GenerateResumesService } from 'src/app/services/generate-resumes.service';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';
import { generateYears } from 'src/app/utils';

interface Date {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss', '../../../assets/scss/forms.scss']
})
export class WorkComponent implements OnInit, OnDestroy {
  @ViewChild('#textarea') input; 

  constructor(private readonly fb: FormBuilder, 
    private readonly router: Router, 
    private readonly resumeDistributor: ResumeDistributorService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly generateResumeService: GenerateResumesService) { }
    isCurrentActionEdit: boolean = false;
    activatedRouteSubscription: Subscription;
    shouldAddWorkDescriptionDisplay: boolean = false;
    shouldJobFormDisplay: boolean = false;
    shouldErrorPopupShow: boolean = false;
    editingResumeObject: any = {
      isResumeBeingEdited: false,
      resumeNumber: 0
    };
    
    shouldEditIconsShow: any = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: -1
    };
    workHistory: FormGroup;
    completedWorkHistory: FormGroup[] = [];
    rewrittenJobText: string = "";
    shouldRewrittenJobDescriptionDisplay: boolean = false;
    isLoading: boolean = false; 
  async ngOnInit(): Promise<void> {
    let resumeInfo = this.resumeDistributor.getResumeDataFromLocalStorage();
    if(resumeInfo?.jobs && resumeInfo.jobs.length > 0) {
      resumeInfo.jobs.forEach(element => {
        const splitStart = element.startDate.split(" ")
        const startMonth = splitStart[0];
        const startYear = splitStart[1];
        if(element.endDate !== 'Current') {
          const splitEnd = element.endDate.split(" ")
          const endMonth = splitEnd[0];
          const endYear = splitEnd[1];
          this.completedWorkHistory.push(this.getFormGroup(element.companyName, element.position, element.endDate === 'Current'? true: false, element.description, startMonth, startYear,endMonth, endYear))
        } else {
          this.completedWorkHistory.push(this.getFormGroup(element.companyName, element.position, element.endDate === 'Current'? true: false, element.description, startMonth, startYear))
        }
    })
    }

    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.isCurrentActionEdit = params?.action==='edit'? true : false;
    });
  }

  addJobForm(companyName? : string, position?: string, currentlyWorksThere?: boolean, description? :string, startMonth?:string ,startYear?: string, endMonth?:string ,endYear?: string) {
    this.shouldJobFormDisplay = true;
    this.workHistory = this.getFormGroup(companyName, position, currentlyWorksThere, description, startMonth,startYear, endMonth ,endYear)
  }

  getFormGroup(companyName? : string, position?: string, currentlyWorksThere?: boolean, description? :string, startMonth?:string ,startYear?: string, endMonth?:string ,endYear?: string) {
    return this.fb.group({
      companyName: this.fb.control(companyName || '', [Validators.required]),
      position: this.fb.control(position || '', [Validators.required]),
      description: this.fb.control(description || '', [Validators.required]),
      startMonth: this.fb.control(startMonth || '' , [Validators.required]),
      startYear: this.fb.control(startYear || '', [Validators.required]),
      endMonth: this.fb.control(endMonth || ''),
      endYear: this.fb.control(endYear || ''),
      currentlyWorksThere: this.fb.control(currentlyWorksThere || false)
    });
  }

  getEndDate(formGroup: FormGroup) {
    if(formGroup.controls.currentlyWorksThere.value) {
      return 'to Current'
    } else {
      return `to ${formGroup.controls.endMonth.value} ${formGroup.controls.endYear.value}`
    }
  }

  startYears = generateYears();
  endYears = ['Current', ...generateYears()];
  months: Date[] = [
    {value: 'January', viewValue: 'January'},
    {value: 'February', viewValue: 'February'},
    {value: 'March', viewValue: 'March'},
    {value: 'April', viewValue: 'April'},
    {value: 'May', viewValue: 'May'},
    {value: 'June', viewValue: 'June'},
    {value: 'July', viewValue: 'July'},
    {value: 'August', viewValue: 'August'},
    {value: 'September', viewValue: 'September'},
    {value: 'October', viewValue: 'October'},
    {value: 'November', viewValue: 'November'},
    {value: 'December', viewValue: 'December'}
  ];

  goBack() {
    if(this.shouldAddWorkDescriptionDisplay) {
      this.shouldJobFormDisplay = true;
      this.shouldAddWorkDescriptionDisplay = false;
    } else {
      this.router.navigate(['/pii'])
    }
  }

  goNext() {
    if(this.isCurrentActionEdit && !this.shouldAddWorkDescriptionDisplay && !this.shouldJobFormDisplay) {
      this.router.navigate(['/preview']);
    }
    else if(this.shouldJobFormDisplay) {
      if(!this.shouldErrorPopupShow) {
        this.shouldAddWorkDescriptionDisplay = true;
        this.shouldJobFormDisplay = false;
      }
    } else if(this.shouldAddWorkDescriptionDisplay) {
      this.completeJob();
    } else {
      this.router.navigate(
        ['/progress'], {queryParams: {completed: 50}});
    }
  }

  editJob(i: any) {
    this.addJobForm(
      this.completedWorkHistory[i].controls.companyName.value,
      this.completedWorkHistory[i].controls.position.value,
      this.completedWorkHistory[i].controls.currentlyWorksThere.value,
      this.completedWorkHistory[i].controls.description.value,
      this.completedWorkHistory[i].controls.startMonth.value,
      this.completedWorkHistory[i].controls.startYear.value,
      this.completedWorkHistory[i].controls.endMonth.value,
      this.completedWorkHistory[i].controls.endYear.value
      )
      this.editingResumeObject = {
        isResumeBeingEdited: true,
        resumeNumber: i
      }
  }

  deleteJob(i: any) {
    this.completedWorkHistory.splice(i, 1);
  }

  cancelEdit() {
    this.shouldEditIconsShow = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: 50
    };
  }

  setEditIcons(i) {
    this.shouldEditIconsShow = {
      shouldEditIconsShow: true,
      indexOfResumeToEdit: i
    };
  }

  checkForErrors() {
    let vars = this.workHistory.controls

    let errors = [];
    for (const [key, value] of Object.entries(vars)) {
      if(value.errors) {
        errors.push(value.errors)
      }
    }

    this.shouldErrorPopupShow = errors.length > 1 ? true: false; 
  }
  completeJob() {
    if(!this.shouldErrorPopupShow) {
        if(this.editingResumeObject.isResumeBeingEdited === true) {
          this.completedWorkHistory.splice(this.editingResumeObject.resumeNumber, 1, this.workHistory)
        } else {
          this.completedWorkHistory.push(this.workHistory);
        }
        this.editingResumeObject = {};
        this.shouldAddWorkDescriptionDisplay = false;
        this.shouldJobFormDisplay = false;
        this.shouldEditIconsShow = {
          shouldEditIconsShow: false,
          indexOfResumeToEdit: -1
        };
      }
    }


  getTextForActionButton() {
    if((this.isCurrentActionEdit && this.shouldAddWorkDescriptionDisplay) || (this.isCurrentActionEdit && !this.shouldAddWorkDescriptionDisplay && !this.shouldJobFormDisplay)) {
      return 'Done'
    } else {
      return 'Next'
    }

  }

  setAITextToDescription() {
    this.workHistory.controls.description.setValue(this.rewrittenJobText);
  }

  rewriteWithAI() {
    this.isLoading = true;
    this.generateResumeService.getRewrittenTextForAI(this.workHistory.controls.description.value).subscribe((res: any) => {
      console.log(res);
      if(res.status === 200) {
        this.isLoading = false;
        this.rewrittenJobText=res.text.message.content;
        this.shouldRewrittenJobDescriptionDisplay = true;
      }
    })
  }
  ngOnDestroy() {
    this.resumeDistributor.addWorkHistory(this.completedWorkHistory);
    this.activatedRouteSubscription.unsubscribe();
  }
}

<div class="content-wrapper" >
  <div class="form-container">
  <span class="section-title">Skills</span>
    <div [hidden]="shouldSkillsFormShow">
      <div *ngFor="let item of completeSkills; let i=index" style="display: flex; flex-direction: column; width: 100%; margin-top: 5px; height: 10%">
       
        <div class="regular-padding" *ngIf="shouldEditIconsShow.indexOfResumeToEdit !== i" (click)="setEditIcons(i)">
          <span class="skills-display">{{item.controls.skillsControl.value}}</span>
        </div>

        <div style="height: 100%" *ngIf="shouldEditIconsShow.shouldEditIconsShow && shouldEditIconsShow.indexOfResumeToEdit === i">
          <button mat-button class="action-buttons" style="background-color: #00A651;" (click)="editSkills(i)">
            <img (click)="editSkills(i)" src="../../../assets/edit-icons/edit.svg" width="25" height="25" >
          </button>
          <button mat-button class="action-buttons" style="background-color: #1529BE;" (click)="cancelEdit()">
            <img (click)="cancelEdit()" src="../../../assets/edit-icons/cancel.svg" height="20" width="20">
          </button>
          <button mat-button class="action-buttons" style="background-color: #A9343D;" (click)="deleteSkill(i)">
            <img (click)="deleteSkill(i)" src="../../../assets/edit-icons/delete.svg" height="25" width="25">
          </button>
        </div>
        <hr class="hr">
      </div>
      <button mat-raised-button (click)="onHandleClick()" class="add-button">+ Add Skill</button>
      <h2 class="suggested-skills-header">Suggested Skills: </h2>
      <div *ngIf="shouldSpinnerShow" style="display: flex; align-items: center;">
        <div class="spinner"></div>
        <div class="spinner-label">Generating skills based off your work experience</div>
      </div>
      <div *ngIf="shouldSuggestedSkillsShow" class="skills-container">
        <div *ngFor="let skill of suggestedSkills" (click)="selectSkill(skill)" class="skill-bubble">{{ skill }}</div>
      </div>

      
    </div>
    <form [formGroup]="skillsForm" class="skills" *ngIf="shouldSkillsFormShow">
      <mat-form-field appearance="fill" style="width: 100%;" class="form-field">
        <input matInput type="text" autofocus formControlName="skillsControl"/>
      </mat-form-field>
      <p *ngIf="shouldErrorPopupShow" class="error-container">There are errors in your form</p>

      <button mat-raised-button (click)="onHandleClick()" class="add-button">Done</button>
    </form>
  </div>

</div>
<div class="two-buttons-wrapper">
  <button class="two-buttons" mat-button (click)="goBack()">Prev</button>
  <button mat-button class="next-button two-buttons" (click)="goNext()">{{isCurrentActionEdit?'Done': 'Next'}}</button>
</div>


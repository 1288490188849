<div class="content-wrapper">
  <div class="form-container">
    <span class="section-title">School History</span>
    <div [hidden]="shouldFormShow">
      <div *ngFor="let item of completedEducationForms; let i=index" style="display: flex; flex-direction: column; width: 100%; margin-top: 5px; height: 10%;">
        <div class="regular-padding completed-items" *ngIf="shouldEditIconsShow.indexOfResumeToEdit !== i" (click)="setEditIcons(i)">
          <span class="school-details">{{item.controls.schoolName.value}}</span>
          <span class="school-details">{{item.controls.major.value}}</span>
          <span class="school-details">{{item.controls.startYear.value}} - {{item.controls.endYear.value}}</span>
        </div>

        <div style="height: 100%" *ngIf="shouldEditIconsShow.shouldEditIconsShow && shouldEditIconsShow.indexOfResumeToEdit === i">
          <button mat-button class="action-buttons" style="background-color: #00A651;" (click)="editSchool(i)">
            <img (click)="editSchool(i)" src="../../../assets/edit-icons/edit.svg" width="25" height="25" >
          </button>
          <button mat-button class="action-buttons" style="background-color: #1529BE;" (click)="cancelEdit()">
            <img (click)="cancelEdit()" src="../../../assets/edit-icons/cancel.svg" height="20" width="20">
          </button>
          <button mat-button class="action-buttons" style="background-color: #A9343D;" (click)="deleteSchool(i)">
            <img (click)="deleteSchool(i)" src="../../../assets/edit-icons/delete.svg" height="20" width="20">
          </button>
        </div>
        <hr class="hr">
      </div>

      <button mat-raised-button (click)="addEducationForm()" class="add-button" >+ Add School</button>
    </div>
    <ng-container *ngIf="shouldFormShow">
    <form  [formGroup]="educationForm" class="resume-form">
      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Name of school: </mat-label>
        <input matInput type="text" formControlName="schoolName"  />
        <mat-error *ngIf="educationForm.controls.schoolName.errors">Name of school is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Degree: </mat-label>
        <input matInput type="text" formControlName="major"  />
        <mat-error *ngIf="educationForm.controls.major.errors">Degree is required</mat-error>
      </mat-form-field>
       
      <div class="dates">
        <mat-form-field appearance="fill" class="date">
          <mat-label>Start year</mat-label>
          <mat-select name="year" formControlName="startYear" style="font-size: 16px;">
            <mat-option *ngFor="let year of startYears" [value]="year" >
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="date">
          <mat-label>End year</mat-label>
          <mat-select name="year" formControlName="endYear" style="font-size: 16px;">
            <mat-option *ngFor="let year of endYears" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <p *ngIf="shouldErrorPopupShow" class="error-container">There are errors in your form</p>

    <button mat-raised-button (click)="completeSchool()" class="add-button" >Done</button>
  </ng-container>
  </div>
</div>  
<div class="two-buttons-wrapper">
  <button class="two-buttons" mat-button (click)="goBack()">Prev</button>
  <button mat-button class="next-button" (click)="goNext()" class="two-buttons">{{getActionButtonText()}}</button>
</div>
  
    
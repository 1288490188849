<div style="display: flex; flex-direction: column; max-width: 1200px; width: 80%; margin: auto;">
  <h1 class="header-text">Blog</h1>

  <div *ngFor="let blog of blogs; index as i">
    <div class="blog-wrapper" (click)="viewBlog(i)">
      <h1 class="blog-title">{{blog.blogTitle}}</h1>
      <p class="blog-content">{{blog.paragraphs[0].paragraph}}</p>
    </div>
  </div>
</div>

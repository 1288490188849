import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateResumesService } from 'src/app/services/generate-resumes.service';

@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit {
  blog = {} as any;
  constructor(private readonly resumeGenerator: GenerateResumesService, private router: Router,private route: ActivatedRoute) { }
  ngOnInit(): void {
    let blogId = this.route.snapshot.paramMap['params'].blogName;
    console.log(blogId)
    this.getBlog(blogId)
  }



  getBlog(blogId: string) {
      this.resumeGenerator.getBlog(blogId).subscribe((res: any ) => {
        this.blog = res.blogItem[0];
        console.log(this.blog)
      });
  }
}

<div
  style="display: flex; flex-direction: column; justify-content: center; width: 80%; margin: auto; max-width: 1200px; padding-top: 4rem">
  <div class="flex-row">
    <div class="header">
      <h1 class="header-text">
        Build your resume in <br>
        simple bite-sized steps.
      </h1>
      <br>
      <h2 class="secondary-text secondary-header">
        Building a professional resume has never
        been easier. 
      </h2>
      <button class="action-button" (click)="getStarted()">Build Your Resume</button>
    </div>
    <img src="../../../assets/resumes/resume-example-two.jpeg" alt="Resume image" width="450px" class="example-resume">
  </div>

<hr class="fading-hr">
  <h2 class="header-text-two">Resumes designed with recruiters and employers in mind.</h2>

  <div class="flex-row">
    <div class="flex-row">
      <img src="../../../assets/pictures/stopwatch.svg" width="80px" alt="Resume clock">
      <h3 class="secondary-text image-text">
        Recruiters spend 6-7 seconds screening resumes. Make one that stands out.
      </h3>
    </div>
    <div class="flex-row">
      <img src="../../../assets/pictures/page-check.svg" width="80px" alt="Examining a paper">
      <h3 class="secondary-text image-text" >
        Templates designed to lead the eye down the page while emphasizing your qualifications.
      </h3>
    </div>
  </div>
  <hr class="fading-hr">

  <h2 class="header-text">Our resume builder is intelligent and easy to use</h2>
  <div class="flex-row">
    <div class="flex-row">
    <img src="../../../assets/pictures/brain.svg" width="90px" alt="Brain">
    <div class="flex-col" >
      <h3 class="secondary-text image-text" >
        Along the way our AI will help you craft concise sentences that will quickly and clearly communicate your
        qualifications.
      </h3>

      <div class="image-text">
      <p class="tell-us" style="padding: unset !important">Tell us something great you did at your last job.</p>
      <input [(ngModel)]="inputText" type="text" class="input-form" placeholder="Enter your greatness here."/>
      <div *ngIf="isLoading" class="flex-row" style="padding-top: 1rem">
        <div class="spinner"></div>
        <div class="spinner-label">Generating text with AI</div>
      </div>
      <div class="rewritten-job" *ngIf="shouldRewrittenJobDescriptionDisplay">
        {{rewrittenJobText}}
      </div>
      <button class="action-button" (click)="rewriteWithAI()">Rewrite with AI</button>
    </div>
    </div>
    </div>

    <div class="flex-row">
      <img src="../../../assets/pictures/process-check.svg" width="90px" alt="Circle">
      <h3 class="secondary-text image-text" >
        We have simplified the process into 4 steps.
        <p class="step">- Personal Information</p>
        <p class="step">- Work History</p>
        <p class="step">- Education</p>
        <p class="step">- Skills</p>
      </h3>
    </div>
  </div>
  <hr class="fading-hr">

  <h2 class="header-text">Just a dollar</h2>
  <div class="flex-row">
    <div class="flex-row">
      <img src="../../../assets/pictures/dollar.svg" width="100px" alt="Dollar">
      <h3 class="secondary-text image-text" >
        A complete resume for just a dollar.</h3>
    </div>

    <div class="flex-row">
      <img src="../../../assets/pictures/refund.svg" width="100px" alt="Dollar">
      <h3 class="secondary-text image-text" >
        Money Back Guarantee.
        If you are not satisfied,
        let us know. We will
        issue you a refund.</h3>
    </div>
  </div>
  <hr class="fading-hr">

  <h2 class="header-text">Get started</h2>
  <div class="flex-row">
    <div class="flex-row">
      <img src="../../../assets/pictures/get-started-rocket.svg" width="100px" alt="Rocket">
      <div class="flex-col professional-check" >
        <div class="flex-col">
          <p class="tell-us" style="padding-top: unset !important;">Enter your email and we will rate its
            professionalism.</p>
          <div class="flex-row">
            <input [(ngModel)]="email" type="text" class="input-form email-text-width"
                   placeholder="Enter your email here"/>
            <button class="check-button" style="margin-top: 1rem; margin-left: unset;" (click)="scoreEmail()">Check
              Professionalism
            </button>
          </div>
          <p *ngIf="shouldEmailScoreShow && emailScore > 2" class="email-score"
             style="padding-top: 1rem; font-size: 30px; display: flex">👍</p>
          <p *ngIf="shouldEmailScoreShow && 3 > emailScore" class="email-score"
             style="padding-top: 1rem; font-size: 30px; display: flex">👎</p>


          <p *ngIf="shouldEmailScoreShow" class="email-score">{{emailSuggestion}}.</p>
          <div *ngIf="shouldSpinnerShow" style="display: flex; padding-top: 1rem">
            <div class="spinner"></div>
            <div class="spinner-label">Rating your email with AI</div>
          </div>
        </div>
        <div class="flex-col">
          <div class="flex-row">
            <div>
              <p class="tell-us">First Name</p>
              <input [(ngModel)]="firstName" type="text" class="input-form" placeholder="First Name"/>
            </div>
            <div>
              <p class="tell-us">Last Name</p>
              <input [(ngModel)]="lastName" type="text" class="input-form" placeholder="Last Name"/>
            </div>
          </div>

          <button class="check-button" style="margin-top: 2rem; margin-left: unset;"
                  (click)="addPiiFromHomepage()">Complete your Resume
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr class="fading-hr">

  <h2 class="header-text" id="faq">FAQ</h2>
  <mat-accordion *ngFor="let faq of faqSource; index as i" style="padding-top: .5rem;">
    <mat-expansion-panel class="mat-panel" (opened)="faq.panelOpenState = true"
                         (closed)="faq.panelOpenState = false">
      <mat-expansion-panel-header [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
        <mat-panel-title class="faq-text">
          {{faq.question}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="faq-text">{{faq.answer}}</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>







   <div style="max-height: 200px; max-width: 300px;">
   <div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
        <div class="header">
          <div class="name">
            {{resumeData.firstName}} {{resumeData.lastName}}
          </div>
          <hr>
            <p class="contact-info">{{formatPhoneNumberFromString(resumeData.phoneNumber)}}  |   {{resumeData.email}}</p>        
        </div>
  
      <p class="section-header">Employment History:</p>
      <div *ngFor="let job of resumeData.jobs;">
      <div class="job-wrapper">
        <p class="job-name-and-position">{{job.position}} at {{job.companyName}}</p>
        <p class="job-tenure">{{job.startDate}} - {{job.endDate}}</p>
      </div>
      <div class="job-description">
          <p class="job-duty"><span>-</span> {{job.description}}</p>
      </div>
      </div>
      <p class="section-header">Education:</p>
      <div *ngFor="let school of resumeData.school;">
      <div class="school-wrapper">
        <p class="school-name-and-major">{{school.schoolName}} - {{school.major}}</p>
        <p class="school-time">{{school.startYear}}  - {{school.endYear}} </p>
      </div>
      </div>
      <p class="section-header">Skills:</p>
        <div class="skills-wrapper" *ngFor="let skill of resumeData.skills;">
          <p class="skill"><span>-</span> {{skill}}</p>
        </div>
    </div>
  </div>
import { Component, Input, OnInit } from '@angular/core';
import { ResumeData } from 'src/app/interfaces/resume-detail.interface';
import { Subscription } from 'rxjs';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';
import { formatPhoneNumber } from 'src/app/utils';

@Component({
  selector: 'app-resume-four',
  templateUrl: './resume-four.component.html',
  styleUrls: ['./resume-four.component.scss']
})
export class ResumeFourComponent implements OnInit {

  constructor(private readonly resumeDistributorService: ResumeDistributorService) { }

  resumeData = {} as ResumeData;
  resumeDataSubscription: Subscription;
  @Input() fullScreen: boolean;

  ngOnInit() {
    this.resumeDataSubscription = this.resumeDistributorService.$resumeDetails.subscribe((res) => {
      this.resumeData = res;
    })
  }

  ngOnDestroy() {
    this.resumeDataSubscription.unsubscribe();
  }
  
  formatPhoneNumberFromString(phoneNumber: any) {
    return formatPhoneNumber(phoneNumber)
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { ResumeData, JobForm, EducationForm } from '../interfaces/resume-detail.interface';
import { GenerateResumesService } from './generate-resumes.service';

@Injectable({
  providedIn: 'root'
})
export class ResumeDistributorService {
  private readonly resumeDetails: ReplaySubject<ResumeData> = new ReplaySubject(1);
  $resumeDetails: Observable<ResumeData> = this.resumeDetails.asObservable();

  resumeInfo = {} as ResumeData;
  constructor(private readonly localStorageService: LocalStorageService,
    private readonly resumeGenerator: GenerateResumesService) {
      this.resumeDetails.next(this.getResumeDataFromLocalStorage())
  }

  getAndSetResumeInfoFromLocalStorage() {
    let resume = this.localStorageService.getResumeDataFromLocalStorage();
    if(resume) {
      this.resumeInfo = resume;
    } else {
      this.resumeInfo = {referenceId: this.resumeGenerator.generateUUID()} as ResumeData;
    }
    resume?.events ? this.resumeInfo.events = resume.events: this.resumeInfo.events = [];
  }

  addResumeNumber(resumeNumber: number) {
    this.getAndSetResumeInfoFromLocalStorage();
    this.resumeInfo.resumeNumber = resumeNumber;
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo);
  }

  addEvent(event: string) {
    this.getAndSetResumeInfoFromLocalStorage();
    if(this.resumeInfo.events) {
      this.resumeInfo.events.push(event);
    } else {
      this.resumeInfo.events = [];
      this.resumeInfo.events.push(event);
    }
    this.resumeGenerator.trackEvent(this.resumeInfo.referenceId, this.resumeInfo)
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo);
  }

  addPii(piiInfo: any) {
    this.getAndSetResumeInfoFromLocalStorage();
    this.resumeInfo.email = piiInfo.controls.emailFormControl.value;
    this.resumeInfo.firstName = piiInfo.controls.firstNameControl.value;
    this.resumeInfo.lastName = piiInfo.controls.lastNameControl.value;
    this.resumeInfo.phoneNumber = piiInfo.controls.phoneNumberControl.value;
    this.resumeInfo.events.push('Added PII')
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo);
    this.resumeGenerator.trackEvent(this.resumeInfo.referenceId, this.resumeInfo)
    this.resumeDetails.next(this.resumeInfo)
  }

  addPiiFromHomepage(piiInfo: any) {
    this.getAndSetResumeInfoFromLocalStorage();
    this.resumeInfo.email = piiInfo.email;
    this.resumeInfo.firstName = piiInfo.firstName;
    this.resumeInfo.lastName = piiInfo.lastName;
    this.resumeInfo.events.push('Added PII from Homepeage');
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo);
    this.resumeGenerator.trackEvent(this.resumeInfo.referenceId, this.resumeInfo)
    this.resumeDetails.next(this.resumeInfo);
  }
  addWorkHistory(workHistory: any) {
    this.getAndSetResumeInfoFromLocalStorage();
    this.resumeInfo.jobs = [];
    for(let i in workHistory) {
      let job = {} as JobForm;
      job.companyName = workHistory[i].controls.companyName.value;
      job.position = workHistory[i].controls.position.value;
      job.description = workHistory[i].controls.description.value;
      job.startDate = `${workHistory[i].controls.startMonth.value} ${workHistory[i].controls.startYear.value}`;
      job.endDate = workHistory[i].controls.currentlyWorksThere.value? 'Current' : `${workHistory[i].controls.endMonth.value} ${workHistory[i].controls.endYear.value}`;

      this.resumeInfo.jobs.push(job)
    }
    this.resumeInfo.events.push('Added Work')
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo)
    this.resumeGenerator.trackEvent(this.resumeInfo.referenceId, this.resumeInfo)
    this.resumeDetails.next(this.resumeInfo)
  }

  addSchoolHistory(schoolHistory: any) {
    this.getAndSetResumeInfoFromLocalStorage();
    this.resumeInfo.school = [];
    for(let i in schoolHistory) {
      let school = {} as EducationForm;
      school.schoolName = schoolHistory[i].controls.schoolName.value;
      school.major = schoolHistory[i].controls.major.value;
      school.startYear = schoolHistory[i].controls.startYear.value;
      school.endYear = schoolHistory[i].controls.endYear.value;
      this.resumeInfo.school.push(school);
    }
    this.resumeInfo.events.push('Added School')
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo)
    this.resumeGenerator.trackEvent(this.resumeInfo.referenceId, this.resumeInfo)
    this.resumeDetails.next(this.resumeInfo)
  }

  addSkills(skills: any) {
    this.getAndSetResumeInfoFromLocalStorage();
    this.resumeInfo.skills = skills.map(element => {
      return element.value.skillsControl
    });
    this.resumeInfo.events.push('Added Skills')
    this.localStorageService.addFormDataToLocalStorage(this.resumeInfo)
    this.resumeGenerator.trackEvent(this.resumeInfo.referenceId, this.resumeInfo)
    this.resumeDetails.next(this.resumeInfo)
  }

  getResumeDataFromLocalStorage() {
    return this.localStorageService.getResumeDataFromLocalStorage();
  }

}

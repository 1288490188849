<div class="privacy">
    <p class="header">Privacy Policy</p>
    <p class="policy">No Data Selling Promise

    We understand the importance of protecting your personal information and are committed to safeguarding your privacy online. This Privacy Policy outlines the information we collect from you, how we use and protect it, and the circumstances under which we may share it with third parties.
    
    <br>
    Collection of Information
    We collect personal information such as your name, email address, and phone number when you provide it to us. We may also collect other information that you provide to us, such as your job title or company name. Additionally, we may automatically collect usage information such as your IP address, browser type, and operating system.
    
    <br>
    <br>
    Use of Information
    We use the information we collect to provide and improve our services, communicate with you about our products and services, and comply with our legal obligations. We do not sell your personal information to third parties for any purpose.
    <br>
    <br>
    Disclosure of Information
    We may share your personal information with third-party service providers who help us operate our business, such as web hosting providers, payment processors, and email service providers. We may also disclose your personal information if required to do so by law, to protect our rights or property, or in the event of a merger, acquisition, or sale of our business.
    <br>
    <br>
    Security
    We take reasonable measures to protect the personal information we collect from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. We use SSL encryption to protect the transmission of information over the internet.
    <br>
    <br>
    Children's Privacy
    Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
    <br>
    <br>
    Changes to this Policy
    We reserve the right to update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will provide notice of any material changes by posting the updated Privacy Policy on our website.
    <br>
    <br>
    Contact Us
    If you have any questions about this Privacy Policy or our data protection practices, please contact us at privacy@rezumaki.com</p></div>

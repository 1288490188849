<div class="content-wrapper">
<form  [formGroup]="resumeForm" class="form-container">
  <div class="section-title">About</div>
    <mat-form-field *ngIf="!shouldAboutFromDisplay" class="form-field" color="warn" appearance="fill">
      <mat-label>First Name</mat-label>
      <input matInput type="text" formControlName="firstNameControl"/>
    </mat-form-field>

    <mat-form-field  *ngIf="!shouldAboutFromDisplay" appearance="fill" class="form-field">
      <mat-label>Last Name</mat-label>
      <input matInput type="text" formControlName="lastNameControl"  />
    </mat-form-field>

    <mat-form-field *ngIf="!shouldAboutFromDisplay" (clickOutside)="scoreEmail()" appearance="fill" class="form-field">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="emailFormControl"  />
    </mat-form-field>
    <span></span>
    <p *ngIf="shouldEmailScoreShow && emailScore > 2" class="email-score">👍</p>
    <p *ngIf="shouldEmailScoreShow && 3 > emailScore" class="email-score">👎</p>


    <p *ngIf="shouldEmailScoreShow" class="email-score">{{emailSuggestion}}</p>
    <div *ngIf="shouldSpinnerShow" style="display: flex">
      <div class="spinner"></div>
      <div class="spinner-label">rating your email with AI</div>
    </div>
    <mat-form-field *ngIf="!shouldAboutFromDisplay" appearance="fill" class="form-field">
      <mat-label>Phone Number</mat-label>
      <input matInput type="tel" formControlName="phoneNumberControl"  />
    </mat-form-field>
    <p *ngIf="shouldErrorPopupShow" class="error-container">There are errors in your form</p>

  </form>
</div>
<div class="next-button-wrapper">
  <button mat-button class="next-button" (click)="goNext()" class="next-button">{{getText()}}</button>
</div>

export const faq = [
  {question: 'How much does it cost to make a resume?', answer: 'Just one dollar. No, we aren\'t joking. It is just a dollar.', panelOpenState: false},
  {question: 'Can I change the language of my resume?', answer: 'Currently, we don\'t support multiple languages but we have it planned in future releases.', panelOpenState: false},
  {question: 'How do you curate your resumes?', answer: 'We research the type of resumes that ATS(Applicant Tracking Systems) respond well to and created resumes based off that.', panelOpenState: false},
  {question: 'Can I download my resume to Word or PDF?', answer: 'Once your resume is ready, you can export your resume to a PDF. Most ATS systems prefer a PDF file.', panelOpenState: false},
  {question: 'Do you offer a money-back guarantee?', answer: 'Yes! We offer a good ol\' fashioned, no questions asked, money-back guarantee within the first 7 days after creating a resume.', panelOpenState: false},
  {question: 'Are resumes built with Rezumaki ATS friendly ', answer: 'Our builder helps optimize your resume for most job applications on the market. The resume suggestions feature will provide helpful hints on things you can improve. Our formatting and design have been tested to match industry standards.', panelOpenState: false},
  {question: 'Is my data safe on Rezumaki.com?', answer: 'Yes! We promise to never sell your data or share it with others. Customer privacy is taken seriously.', panelOpenState: false},

];

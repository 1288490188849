<div style="max-height: 200px; max-width: 300px;">
  <div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
      <div class="header">
        <div class="name">
          {{resumeData.firstName}} {{resumeData.lastName}}
        </div>
          <span class="contact-info">{{resumeData.phoneNumber}}   &#x2022;  {{resumeData.email}}</span>  
      </div>
  
  <div style="display: flex; ">
    <p class="section-header">Experience</p>
    <div style="display: flex; flex-direction: column; margin-top: 1%;">
    <div *ngFor="let job of resumeData.jobs;" class="job-container">
      <div class="job-wrapper">
          <p class="job-name-and-position">{{job.position}}, {{job.companyName}}</p>
          <p class="job-tenure">{{job.startDate}} - {{job.endDate}}</p>
      </div>
      <div class="job-description">
          <p class="job-duty"><span>&#x2022;</span> {{job.description}}</p>
      </div>
    </div>
      </div>
  </div>
  <div style="display: flex; ">
    <p class="section-header">Education</p>
    <div style="display: flex; flex-direction: column; margin-left: 2%; margin-top: 1%;">
  
      <div *ngFor="let school of resumeData.school;" class="school-container">
      <div class="school-wrapper">
          <p class="school-name">{{school.schoolName}} - {{school.major}}</p>
          <p class="school-time">{{school.startYear}}  - {{school.endYear}} </p>
      </div>
      </div>
      </div>
  </div>
    <p class="section-header">Skills</p>
      <div class="skills-wrapper"  *ngFor="let skill of resumeData.skills;">
        <span class="skill"><span>&#x2022;</span> {{skill}}</span>
      </div>
  </div>
  </div>
  
  
  
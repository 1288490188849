import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';
import { generateYears } from 'src/app/utils';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss', '../../../assets/scss/forms.scss']
})
export class SchoolComponent implements OnInit, OnDestroy {
  constructor(private readonly fb: FormBuilder, 
    private readonly router: Router, 
    private readonly resumeDistrubutorService: ResumeDistributorService,
    private readonly activatedRoute: ActivatedRoute) { }
    isCurrentActionEdit: boolean = false;
    activatedRouteSubscription: Subscription;
    shouldErrorPopupShow: boolean = false;

  ngOnInit(): void {
    window.scrollTo(0,0);
    let resumeInfo = this.resumeDistrubutorService.getResumeDataFromLocalStorage();
    if(resumeInfo.school && resumeInfo.school.length > 0) {
      resumeInfo.school.forEach(element => {
        this.completedEducationForms.push(
          this.getEducationFormGroup(element.schoolName, element.major, element.startYear, element.endYear)
          )
      })
    }

    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.isCurrentActionEdit  = params?.action==='edit'? true : false;
    });
  }
  shouldEditIconsShow: any = {
    shouldEditIconsShow: false,
    indexOfResumeToEdit: -1
  };
  educationForm: FormGroup;
  completedEducationForms: FormGroup[] = [];
  startYears = generateYears();
  endYears = ['Current', ...generateYears()];
  shouldFormShow: boolean = false;
  editingResumeObject: any = {
    isResumeBeingEdited: false,
    resumeNumber: 0
  };

  addEducationForm(schoolName? :string, major? : string, startYear?: string, endYear?: string) {
    this.shouldFormShow = true;
    this.educationForm = this.getEducationFormGroup(schoolName, major, startYear, endYear);
  }

  getEducationFormGroup(schoolName? :string, major? : string, startYear?: string, endYear?: string) {
    return this.fb.group({
      schoolName: this.fb.control(schoolName || '', [Validators.required]),
      major: this.fb.control(major || '', [Validators.required]),
      startYear: this.fb.control(startYear || '', [Validators.required]),
      endYear: this.fb.control(endYear || '', [Validators.required])
    });
  }
  

  completeSchool() {
    this.checkForErrors();
    if(!this.shouldErrorPopupShow) {
      if(this.editingResumeObject.isResumeBeingEdited) {
        this.completedEducationForms.splice(this.editingResumeObject.resumeNumber, 1, this.educationForm)
      } else {
        this.completedEducationForms.push(this.educationForm);
      }
      this.shouldFormShow = false;
      this.editingResumeObject = {
        isResumeBeingEdited: false,
        resumeNumber: 0
      };
      this.shouldEditIconsShow = {
        shouldEditIconsShow: false,
        indexOfResumeToEdit: 50
      };
    }
  }

  checkForErrors() {
    let vars = this.educationForm.controls;
    let errors = [];
    for (const [key, value] of Object.entries(vars)) {
      if(value.errors) {
        errors.push(value.errors)
      }
    }

    this.shouldErrorPopupShow = errors.length > 0 ? true: false; 
  }

  determinNextAction() {
    if(this.shouldFormShow) {
        this.completeSchool();
    } else {
      this.addEducationForm();
    }
  }

  deleteSchool(i: any) {
    this.completedEducationForms.splice(i, 1);
    this.shouldEditIconsShow = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: 50
    };
  }

  cancelEdit() {
    this.shouldEditIconsShow = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: 50
    };
  }

  setEditIcons(i) {
    this.shouldEditIconsShow = {
      shouldEditIconsShow: true,
      indexOfResumeToEdit: i
    };
  }

  goBack() {
    this.router.navigate(['/work']) 
  }

  goNext() {
    if(this.isCurrentActionEdit) {
      this.router.navigate(['/preview']);
    }
    else if(this.shouldFormShow) {
      this.completeSchool();
    } else {
      this.router.navigate(
        ['/progress'], {queryParams: {completed: 75}});
    }
  }


  getActionButtonText() {
    return this.isCurrentActionEdit? 'DONE': 'Next'
  }

  editSchool(i) {
    this.editingResumeObject = {
      isResumeBeingEdited: true,
      resumeNumber: i
    };

    this.addEducationForm(this.completedEducationForms[i].controls.schoolName.value,
      this.completedEducationForms[i].controls.major.value,
      this.completedEducationForms[i].controls.startYear.value,
      this.completedEducationForms[i].controls.endYear.value);
    this.shouldEditIconsShow = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: 50
    };
  }

ngOnDestroy() {
   this.resumeDistrubutorService.addSchoolHistory(this.completedEducationForms)
   this.activatedRouteSubscription.unsubscribe();
}
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { WorkComponent } from './components/work/work.component';
import { SchoolComponent } from './components/school/school.component';
import { PiiComponent } from './components/pii/pii.component';
import { SkillsComponent } from './components/skills/skills.component';
import { ResumePreviewComponent } from './components/resume-preview/resume-preview.component';
import { ProgressMarkerComponent } from './global/progress-marker/progress-marker.component';
import { SuccessPageComponent } from './components/success/success-page/success-page.component';
import { PrivacyPolicyComponent } from './global/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './global/terms-of-service/terms-of-service.component';
import {BlogComponent} from './components/blog/blog.component';
import { BlogItemComponent } from './components/blog/blog-item/blog-item.component';


export const routes: Routes = [{
  path: '',
  redirectTo: 'home',
  pathMatch: 'full'
},
{
  path: 'home',
  component: HomepageComponent
},
{
  path: 'work',
  component: WorkComponent
},
{
  path: 'school',
  component: SchoolComponent
},
{
  path: 'skills',
  component: SkillsComponent
},
{
  path: 'pii',
  component: PiiComponent
},
{
  path: 'preview',
  component: ResumePreviewComponent
},
{
  path: 'progress',
  component: ProgressMarkerComponent
},
{
  path: 'success',
  component: SuccessPageComponent
},
{
  path: 'privacy',
  component: PrivacyPolicyComponent
},
{
  path: 'terms',
  component: TermsOfServiceComponent
},
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:blogName',
    component: BlogItemComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div style="max-height: 200px; max-width: 300px;">
  <div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
      <div class="header">
        <span class="name default-left-padding">{{resumeData.firstName}} {{resumeData.lastName}}</span>
        <hr>
        <span class="contact-info-email default-left-padding">{{resumeData.email}}</span>
        <span class="contact-info-phone default-left-padding" style="padding-top: 0.5%;">{{formatPhoneNumberFromString(resumeData.phoneNumber)}}</span>
      </div>    

    <p class="section-header">Work History</p>
    <hr>
    <div *ngFor="let job of resumeData.jobs;">
      <div class="job-wrapper">
        <div style="display: flex;">
          <p> &bull; </p>
          <p class="jobs"> 
            <span >{{job.companyName}} | {{job.position}}</span>
            <span class="gray-color">{{job.startDate}} - {{job.endDate}}</span>
            <span class="job-description">{{job.description}}</span></p>
        </div>
      </div>
    </div>

    <p class="section-header">Education</p>
    <hr>
    <div *ngFor="let school of resumeData.school;">
      <div class="school-wrapper">
        &bull;
        <div style="padding-left: 10px; font-family: Avenir, serif;">
           {{school.schoolName}} | {{school.major}} <br>
          <span class="gray-color">{{school.startYear}} - {{school.endYear}} </span>
        </div>
      </div>
    </div>

    <p class="section-header">Skills</p>
    <hr style="margin-bottom: 2%;">
      <div class="skill-wrapper" *ngFor="let skill of resumeData.skills; let i = index;">
        <span> &bull; <span class="skill">{{skill}}</span></span>
    </div>
  </div>
</div>
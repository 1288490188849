<div class="content-wrapper">
	<div class="global-marker">
	    <p class="text-style">Congratulations</p>
	    <div class="percent-complete-wrapper">
	        <p class="percent-complete">{{percentCompleted}}%</p>
	    </div>
    	<p class="text-style complete">COMPLETE</p>
	</div>
</div>    
<div class="two-buttons-wrapper">
    <button class="two-buttons" mat-button (click)="goBack()">Prev</button>
    <button mat-button class="next-button" (click)="goNext()" class="two-buttons">Next</button>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { HomepageComponent } from './homepage.component';
import { PiiModule } from '../pii/pii.module';
import {FormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  declarations: [HomepageComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PiiModule,
    FormsModule,
    MatExpansionModule

  ], exports: [HomepageComponent]
})
export class HomepageModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { CssHelperService } from 'src/app/services/payment-service.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-global-marker',
  templateUrl: './global-marker.component.html',
  styleUrls: ['./global-marker.component.scss']
})
export class GlobalMarkerComponent implements OnInit {
  navigation: Subscription;
  shouldNavigationDisplay: boolean = false;
  shouldPiiBeHighlighted: boolean = false;
  shouldWorkBeHighlighted: boolean = false;
  shouldSchoolBeHighlighted: boolean = false;
  shouldSkillsBeHighlighted: boolean = false;
  shouldBiggerHeaderShow: boolean = false;
  shouldPreviewBeHighighted: boolean = false;
  currentStep: number = 0;

  constructor(
    private readonly navigationService: NavigationService, 
    private readonly router: Router,
    private readonly cssHelper: CssHelperService) { 
      this.resetHighlight();
    }

  
  ngOnInit(): void {
    this.cssHelper.$editResume.subscribe((val) => {
        this.shouldBiggerHeaderShow = val;
    })

    this.navigation = this.navigationService.$navigation.subscribe((route) => {
      this.shouldBiggerHeaderShow = false;
      this.resetHighlight();
      if(route.urlAfterRedirects.includes('/pii') || route.urlAfterRedirects.includes('/school') || route.urlAfterRedirects.includes('/work') || route.urlAfterRedirects.includes('/skills') || route.urlAfterRedirects.includes('/progress') || route.urlAfterRedirects.includes('/preview') || route.urlAfterRedirects.includes('/success')) {
        document.body.classList.add('not-home-page');
        this.shouldNavigationDisplay = true;
        if(route.urlAfterRedirects.includes('/pii') || route.urlAfterRedirects.includes('25')) {
          this.currentStep = 0;
          this.shouldPiiBeHighlighted = true;
          this.shouldPreviewBeHighighted = false;
        } else if(route.urlAfterRedirects.includes('/work') || route.urlAfterRedirects.includes('50')) {
          this.currentStep = 1;
          this.shouldPiiBeHighlighted = true;
          this.shouldWorkBeHighlighted = true;
          this.shouldPreviewBeHighighted = false;
        } else if(route.urlAfterRedirects.includes('/school') || route.urlAfterRedirects.includes('75')) {
          this.currentStep = 2;
          this.shouldPiiBeHighlighted = true;
          this.shouldWorkBeHighlighted = true;
          this.shouldSchoolBeHighlighted = true;
          this.shouldPreviewBeHighighted = false;
        } else if (route.urlAfterRedirects.includes('/skills')) {
          this.currentStep = 3;
          this.shouldPiiBeHighlighted = true;
          this.shouldWorkBeHighlighted = true;
          this.shouldSchoolBeHighlighted = true;
          this.shouldSkillsBeHighlighted = true;
          this.shouldPreviewBeHighighted = false;
        } else if (route.urlAfterRedirects.includes('/preview') ||  route.urlAfterRedirects.includes('/success')) {
          this.currentStep = 4;
          this.shouldPiiBeHighlighted = true;
          this.shouldWorkBeHighlighted = true;
          this.shouldSchoolBeHighlighted = true;
          this.shouldSkillsBeHighlighted = true;
          this.shouldPreviewBeHighighted = true;
        }
      } else {
        this.shouldNavigationDisplay = false;
        document.body.classList.remove('not-home-page');
      }
    })
  }

  returnButtonStateClass(step: number):string {
    if( step === this.currentStep ){
      return 'svg-wrappers-highlighted';
    } else if( step > this.currentStep ){
      return 'svg-wrappers';
    } else if( step < this.currentStep ){
      return 'svg-wrappers-highlighted-visited';
    }
  }

  navigateTo(route) {
    if(this.router.url === '/preview') {
      this.router.navigate([`${route}`], 
      {queryParams: {action: 'edit'}})
    } else {
      this.router.navigate([`${route}`])
    }
  }

  resetHighlight():void{
    this.shouldPiiBeHighlighted = false;
    this.shouldWorkBeHighlighted = false;
    this.shouldSchoolBeHighlighted = false;
    this.shouldSkillsBeHighlighted = false;
    this.shouldBiggerHeaderShow = false;
    this.shouldPreviewBeHighighted = false;
  }
}

<div style="max-height: 200px; max-width: 300px;">
<div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
    <div>
      <div class="header">
        <span class="name">{{resumeData.firstName}} {{resumeData.lastName}}</span>
        <span class="contact-info">{{resumeData.email}} | {{formatPhoneNumberFromString(resumeData.phoneNumber)}}</span>

      </div>
    </div>

    <p class="section-header">Skills</p>
      <div class="skill-wrapper">
        <div *ngFor="let skill of resumeData.skills; let i = index;">
          <span class="skill">&#x2022; <span style="margin-left: 1%;">{{skill}}</span></span>
        </div>
      </div>

      <p class="section-header">Work Experience</p>

    <div *ngFor="let job of resumeData.jobs;">
      <div style="display: flex;">
        <p class="job-bullet" >&#x2022;</p>

      <div class="job-wrapper">
        <span class="job-tenure">{{job.position}} | {{job.companyName}} | {{job.startDate}} - {{job.endDate}}</span>
        <span class="job-duty">{{job.description}}</span>
      </div>
    </div>
    </div>

      <p class="section-header">Education</p>
    

    <div class="school-wrapper" *ngFor="let school of resumeData.school;">
      <div style="display: flex;">
        <span style="font-size: 19px; margin-top: -2px;">&#x2022;</span>
        <div style="display: flex; flex-direction: column; margin-left: 1%;">
          <span class="school-name-major">{{school.schoolName}} | {{school.major}} | {{school.startYear}} - {{school.endYear}}</span>
        </div>
    </div>
    </div>
    

  </div>
</div>
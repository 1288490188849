<div style="max-height: 200px; max-width: 300px;">

<div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
    <div>
      <div class="header">
        <span class="name">{{resumeData.firstName}} {{resumeData.lastName}}</span>
        <div style="border: thin solid lightgray; width: 95%; margin-top: 2%"></div>
        <span class="contact-info-email">{{resumeData.email}}</span>
        <span class="contact-info-phone">{{resumeData.phoneNumber}}</span>
      </div>
    </div>

    <div class="section-text-wrapper">
      <span class="header-text">Work History</span>
      <div style="border: thin solid lightgray; width: 95%; margin-top: 1%"></div>
    </div>

    <div *ngFor="let job of resumeData.jobs;">
      <div style="display: flex;">
        <p class="job-bullet" >&#x2022;</p>

      <div class="job-wrapper">
        <span class="job-tenure">{{job.position}} at {{job.companyName}}</span>
        <span class="job-description" >{{job.startDate}} - {{job.endDate}}</span>
        <span class="job-duty">{{job.description}}</span>
      </div>
    </div>
    </div>

    <div class="section-text-wrapper">
      <span class="header-text">Education</span>
      <div style="border: thin solid lightgray; width: 95%; margin-top: 1%"></div>
    </div>

    <div class="school-wrapper" *ngFor="let school of resumeData.school;">
      <div style="display: flex;">
        <span style="font-size: 19px; margin-top: -3px;">&#x2022;</span>
        <div style="display: flex; flex-direction: column; margin-left: 1%;">
          <span class="school-name-major">{{school.schoolName}} - {{school.major}}</span>
          <span class="school-years">{{school.startYear}} - {{school.endYear}}</span>
        </div>
    </div>
    </div>
    
    <div class="section-text-wrapper">
      <span class="header-text">Skills</span>
      <div style="border: thin solid lightgray; width: 95%; margin-top: 1%"></div>
    </div>
    <div class="skill-wrapper">
      <div *ngFor="let skill of resumeData.skills; let i = index;">
        <span class="skill">&#x2022; <span style="margin-left: 1%;">{{skill}}</span></span>
      </div>
    </div>
  </div>

</div>
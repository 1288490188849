import { Component, OnInit } from '@angular/core';
import {GenerateResumesService} from "../../services/generate-resumes.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor(private readonly resumeGenerator: GenerateResumesService, private router: Router,) { }
  blogs = [
  ];
  ngOnInit() {
    this.getBlogs();
  }
getBlogs() {
    this.resumeGenerator.getBlogs().subscribe((res: any ) => {
      console.log(res.result.blogs)
      console.log(res.result.blogs[0].blogTitle.S)
      this.blogs = res.result.blogs;
    });
}

  viewBlog(index: string) {
    let blog = this.blogs[index].blogTitle.replace(/\s+/g, '-').toLowerCase();
    this.router.navigateByUrl(`/blog/${blog}`);
  }


}

import { Injectable } from '@angular/core';
import { ResumeData } from '../interfaces/resume-detail.interface';
import { GenerateResumesService } from './generate-resumes.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  addFormDataToLocalStorage(resumeInfo: ResumeData) {
    localStorage.setItem('resumeInfo', JSON.stringify(resumeInfo))
  }

  getResumeDataFromLocalStorage(): ResumeData {
    const localStorageData: ResumeData = JSON.parse(localStorage.getItem('resumeInfo'));
    return localStorageData;
  }
}

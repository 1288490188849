import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly navigation: Subject<any> = new Subject();
  $navigation: Observable<any> = this.navigation.asObservable();
  
  constructor(private router: Router) {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.navigation.next(route)
      }
   });
   }
}

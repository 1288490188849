import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {COMMA, E, ENTER} from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';
import { relativeTimeThreshold } from 'moment';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';
import { Subscription } from 'rxjs';
import { GenerateResumesService } from 'src/app/services/generate-resumes.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss', '../../../assets/scss/forms.scss']
})
export class SkillsComponent implements OnInit, OnDestroy {

  constructor(private readonly fb: FormBuilder, 
    private readonly router: Router, 
    private readonly resumeDistributorService: ResumeDistributorService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly generateResumeService: GenerateResumesService) { }
  completeSkills: Array<FormGroup> = [];
  editingResumeObject: any = {
    isResumeBeingEdited: false,
    resumeNumber: 0
  };
  shouldEditIconsShow: any = {
    shouldEditIconsShow: false,
    indexOfResumeToEdit: -1
  };
  isCurrentActionEdit: boolean = false;
  activatedRouteSubscription: Subscription;
  shouldErrorPopupShow: boolean = false;
  shouldSkillsFormShow: boolean = false;
  skillsForm: FormGroup = this.fb.group({
    skillsControl: this.fb.control('', [Validators.required]),
  });
  suggestedSkills: Array<string> = [];
  shouldSuggestedSkillsShow: boolean = false;
  shouldSpinnerShow: boolean = false;

  ngOnInit(): void {
    let resumeInfo = this.resumeDistributorService.getResumeDataFromLocalStorage();
    if(resumeInfo.jobs) {
      let jobs = resumeInfo.jobs.map(job => {
        return job.position + job.description;
      })
      
      this.getJobSkills(jobs.join())
    }
    if(resumeInfo.skills && resumeInfo.skills.length > 0) {
      resumeInfo.skills.forEach(element => {
        this.completeSkills.push(
        this.fb.group({
          skillsControl: this.fb.control(element || '', [Validators.required]),
        }))
      })
    }

    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.isCurrentActionEdit  = params?.action==='edit'? true : false;
    });
  }

  selectSkill(skill: string): void {
    this.completeSkills.push(this.fb.group({
      skillsControl: this.fb.control(skill || '', [Validators.required]),
    }))
  }

  editSkills(i) {
    this.editingResumeObject = {
      isResumeBeingEdited: true,
      resumeNumber: i
    }
    this.onAddNewSkills(this.completeSkills[i].controls.skillsControl.value);
  }

  deleteSkill(i: any) {
    this.completeSkills.splice(i, 1);
    this.shouldEditIconsShow = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: 50
    };
  }

  cancelEdit() {
    this.shouldEditIconsShow = {
      shouldEditIconsShow: false,
      indexOfResumeToEdit: 50
    };
  }

  setEditIcons(i) {
    this.shouldEditIconsShow = {
      shouldEditIconsShow: true,
      indexOfResumeToEdit: i
    };
  }

  checkForErrors() {
    let vars = this.skillsForm.controls;
    console.log(vars)
    let errors = [];
    for (const [key, value] of Object.entries(vars)) {
      if(value.errors) {
        errors.push(value.errors)
      }
    }

    this.shouldErrorPopupShow = errors.length > 0 ? true: false; 
  }

  onAddNewSkills(skill?: string) {
    this.shouldSkillsFormShow = true;
    this.skillsForm = this.fb.group({
      skillsControl: this.fb.control(skill || '', [Validators.required]),
    });

  }

  goNext() {
    if(this.shouldSkillsFormShow) {
      this.completeSkills.push(this.skillsForm);
      this.onAddNewSkills();
      this.shouldSkillsFormShow = false;
    } else {
      this.router.navigate(['/preview'])
    }
  }

  getJobSkills(jobsDetails: string) {
    this.shouldSpinnerShow = true;
    this.generateResumeService.getJobSkills(jobsDetails).subscribe((res:any) => {
      this.suggestedSkills = res.text.message.content.split(',')

      this.shouldSuggestedSkillsShow = true;
      this.shouldSpinnerShow = false;
    })
  }
  onHandleClick() {
      if(this.editingResumeObject.isResumeBeingEdited) {
        this.completeSkills.splice(this.editingResumeObject.resumeNumber, 1, this.skillsForm)
        this.editingResumeObject = {
          isResumeBeingEdited: false,
          resumeNumber: 0
        };
        this.shouldSkillsFormShow = false;
      } else if(this.shouldSkillsFormShow) {
        this.checkForErrors();
        if(!this.shouldErrorPopupShow) {
          this.completeSkills.push(this.skillsForm);
          this.onAddNewSkills();
          this.shouldSkillsFormShow = false;
        }
      } else {
        this.shouldSkillsFormShow = true;
      }
      this.shouldEditIconsShow = {
        shouldEditIconsShow: false,
        indexOfResumeToEdit: 50
      };
  }

goBack() {
  if(this.shouldSkillsFormShow) {
    this.shouldSkillsFormShow = false;
    this.skillsForm.controls.skillsControl.setValue('')
  } else {
    this.router.navigate(['/school'])
  }
}

ngOnDestroy() {
  this.resumeDistributorService.addSkills(this.completeSkills );
  this.activatedRouteSubscription.unsubscribe();
}

}

import { Component, OnInit } from '@angular/core';
import { Router, } from '@angular/router';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';
import {last} from "rxjs";
import {GenerateResumesService} from "../../services/generate-resumes.service";
import {faq} from "./FAQ";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(private router: Router, private readonly resumeDistributorService: ResumeDistributorService, private readonly resumeGenerator: GenerateResumesService) { }
  panelOpenState = false;
  faqSource = faq;
  inputText = '';
  firstName = '';
  lastName = '';
  email = '';

  onButtonClick() {
    console.log(this.inputText);
    // Add your logic here to handle the button click event
  }
  ngOnInit(): void {
    this.resumeDistributorService.addEvent('Landed on Hompeage')
    document.body.classList.add('home-page');
  }

  getStarted() {
    this.resumeDistributorService.addEvent('Clicked on Get Started')
    this.router.navigate(['/pii']);
  }

  rewriteWithAI() {
    this.isLoading = true;
    this.resumeGenerator.getRewrittenTextForAI(this.inputText).subscribe((res: any) => {
      if(res.status === 200) {
        this.isLoading = false;
        this.rewrittenJobText = res.text.message.content;
        this.shouldRewrittenJobDescriptionDisplay = true;
      }
    });
    this.resumeDistributorService.addEvent('Rewrite text with AI')
  }

  rewrittenJobText: string = "";
  shouldRewrittenJobDescriptionDisplay: boolean = false;
  isLoading: boolean = false;
  shouldEmailScoreShow:boolean = false;
  shouldSpinnerShow: boolean = false;
  emailScore: number = 0;
  emailSuggestion: string = "";
  scoreEmail() {

      this.shouldSpinnerShow = true;
      this.shouldEmailScoreShow = false;
      this.resumeGenerator.scoreEmail(this.email).subscribe((res: any) => {
        if(res && res?.status === 200) {
          const emailTest = res.text.replace(/\n/g, ' ').replace(".", "").split(" ");
          const number = emailTest[0];
          emailTest.shift();
          emailTest.shift();
          emailTest.shift();
          const suggestion = emailTest;
          this.emailScore = Number(number);
          this.emailSuggestion = suggestion.join(' ');
          this.shouldEmailScoreShow = true;
          this.shouldSpinnerShow = false;
        }
      }, err => {
        this.emailScore = 0;
        this.emailSuggestion = 'Oops.. error getting your email score';
        this.shouldEmailScoreShow = true;
        this.shouldSpinnerShow = false;
      });
      this.resumeDistributorService.addEvent('Scored Email')

    }

    addPiiFromHomepage() {
      this.resumeDistributorService.addPiiFromHomepage({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email
      });
      this.getStarted();
  }
}

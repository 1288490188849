import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalMarkerComponent } from './global-marker/global-marker.component';
import { ProgressMarkerComponent } from './progress-marker/progress-marker.component';
import { MatDialog, MatDialogActions } from '@angular/material/dialog';
import { ResumeOneComponent } from '../components/resume-preview/resume-one/resume-one.component';
import { ResumePreviewModule } from '../components/resume-preview/resume-preview.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';



@NgModule({
  declarations: [
    GlobalMarkerComponent,
    ProgressMarkerComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent
  ],
  imports: [
    CommonModule,
    ResumePreviewModule
  ],
   exports: [
    GlobalMarkerComponent,
    ProgressMarkerComponent,
    PrivacyPolicyComponent

   ]
})
export class GlobalModule { }

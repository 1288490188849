export function generateYears() { 
    let years = [];
    let i = 95;

    while(i<100) {
      years.push(`19${i}`)
      i++;
    };
    i = 0;
    while(i < 24) {
      const yearTail = i < 10? `0${i}`: `${i}` 
      years.push(`20${yearTail}`)
      i++;
    }
    return years.reverse();  
  } 

export function formatPhoneNumber(phoneNumber) {
    const cleaned = (` ${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return `(${match[1]}) ${match[2]}-${match[3]}`;
}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ResumeData, RezhelpBackendResponse } from '../interfaces/resume-detail.interface';


@Injectable({
  providedIn: 'root'
})
export class GenerateResumesService {
  private readonly rezhelpBackend: Subject<any> = new Subject();
  $rezhelpBackend: Observable<any> = this.rezhelpBackend.asObservable();
  constructor(private http: HttpClient) { }
  callBackend(resumeInfo: ResumeData) {
      const yourHeader: HttpHeaders = new HttpHeaders({
        ContentType: 'application/json'
    });
    this.http.post('https://stingray-app-s5kuv.ondigitalocean.app/build-resume', resumeInfo, {
      headers: yourHeader
    }
    ).subscribe((response: any) => {
      this.rezhelpBackend.next(response)
    });
  }

  trackEvent(uuid = this.generateUUID(), resumeInfo: ResumeData) {
    const yourHeader: HttpHeaders = new HttpHeaders({
      ContentType: 'application/json'
    });

    const postBody = {
      uuid: uuid,
      data: resumeInfo
    }
    this.http.post('https://stingray-app-s5kuv.ondigitalocean.app/send-resume-info', postBody, {
      headers: yourHeader
    }
    ).subscribe(() => {
      //Dont do anything with info.
    });
  }

  scoreEmail(email: string) {
    return this.http.get(`https://stingray-app-s5kuv.ondigitalocean.app/rate-email?email=${email}`)
  }

  getRewrittenTextForAI(description: string) {
    return this.http.get(`https://stingray-app-s5kuv.ondigitalocean.app/rewrite-description?description=${description}`)
  }

  getJobSkills(description: string) {
    return this.http.get(`https://stingray-app-s5kuv.ondigitalocean.app/generate-skills?job=${description}`)
  }

  getBlogs() {
    return this.http.get(`https://stingray-app-s5kuv.ondigitalocean.app/blogs`)
  }

  getBlog(blogId: string) {
    return this.http.get(`https://stingray-app-s5kuv.ondigitalocean.app/single-blog?blogName=${blogId}`)
  }


  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

}}

<div style="max-height: 200px; max-width: 300px;">
   <div [ngClass]="fullScreen? 'no-resume': 'resume'"  id="resume">
        <div class="header">
          <div class="name">
            {{resumeData.firstName}} {{resumeData.lastName}}
          </div>
          <span class="contact-info">{{formatPhoneNumberFromString(resumeData.phoneNumber)}} - {{resumeData.email}}</span>        
        </div>
  
      <div class="section-header">EXPERIENCE</div>
      <div *ngFor="let job of resumeData.jobs;" class="job-container">
      <div class="job-wrapper">
        <span class="job-tenure">{{job.startDate}} - {{job.endDate}}</span>
        <span class="job-name">{{job.companyName}} - <span class="job-position">{{job.position}}</span></span>
        <span class="job-duty">{{job.description}}</span>

      </div>
      </div>
      <div class="section-header">EDUCATION</div>
      <div *ngFor="let school of resumeData.school;" class="school-container">
      <div class="school-wrapper">
        <span class="school-time">{{school.startYear}}  - {{school.endYear}} </span>
        <span class="school-name">{{school.schoolName}} - <span class="job-position"> {{school.major}}</span></span>
      </div>
      </div>
      <div class="section-header">SKILLS</div>
        <div class="skills-wrapper" *ngFor="let skill of resumeData.skills;">
          <span class="skill">{{skill}}</span>
        </div>
    </div>
  </div>

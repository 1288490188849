import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CssHelperService } from 'src/app/services/payment-service.service';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';

@Component({
  selector: 'app-resume-preview',
  templateUrl: './resume-preview.component.html',
  styleUrls: ['./resume-preview.component.scss']
})

export class ResumePreviewComponent implements OnInit {

  constructor(private readonly resumeDistributorService: ResumeDistributorService,
    private readonly CssHelper: CssHelperService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router) { }
    activatedRouteSubscription: Subscription;
    shouldCancelEditShow: boolean = false;
    resume = 1;
    shouldFullScreenShow: boolean = false;

    ngOnInit() {
      let resInfo = this.resumeDistributorService.getResumeDataFromLocalStorage();
      if(resInfo.resumeNumber) {
        this.resume = resInfo.resumeNumber
      }
      this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params) => {
        const didUserPay  = params.checkout_session? true : false;
        if(params.checkout_session) {
          this.directUserToPaymentOrSuccessPage(didUserPay)
        }

       }); 
    }

  toggleFullScreen() {
    this.shouldFullScreenShow = true;
  }

  toggleFullScreenOff() {
    this.shouldFullScreenShow = false;
  }

  showAnotherResume() {
    if(this.resume == 7) {
      this.resume = 1;
    } else {
      this.resume++
    }
  }

  goBackOneResume() {
    if(this.resume == 1) {
      this.resume = 7;
    } else {
      this.resume += -1
    }
  }

  editResume() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.shouldCancelEditShow = true;
    this.CssHelper.setEditStatusToTrue();
  }

  cancelEdit() {
    this.shouldCancelEditShow = false;
    this.CssHelper.setEditStatusToFalse();
  }

  directUserToPaymentOrSuccessPage(didUserPay: Boolean = false) {
    let resInfo = this.resumeDistributorService.getResumeDataFromLocalStorage();
    this.resumeDistributorService.addResumeNumber(this.resume)
    this.resumeDistributorService.addResumeNumber(this.resume)
    this.router.navigate(['/success'])
    // if(!didUserPay) {
    //   this.resumeDistributorService.addResumeNumber(this.resume)
    //    window.location.href = `https://buy.stripe.com/bIY4iZapMdpLaHK4gg?prefilled_email=${resInfo.email}` 
    // } else {
    //   this.resumeDistributorService.addResumeNumber(this.resume)
    //   this.router.navigate(['/success'])
    // }
  }
}

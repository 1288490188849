<div style="display: flex; flex-direction: column; background: rgba(0, 0, 0, 0.5);
margin-top: 20px; min-height: 600px;">
<div style="padding-left: 8%; padding-top: 2rem">
  <span class="section-title">Preview</span>
  <img (click)="editResume()" [hidden]="shouldCancelEditShow" style="padding-left: 9rem;"src="../../../assets/edit-icons/edit.svg" width="30" height="30" >
  <img (click)="cancelEdit()" [hidden]="!shouldCancelEditShow" style="padding-left: 9rem;"src="../../../assets/edit-icons/cancel.svg"  width="27.356" height="27.341" >
</div>
  
<div class="overlay" style="z-index: 2" (click)="toggleFullScreenOff()" *ngIf="shouldFullScreenShow">
  <div style="display:flex; justify-content: flex-end; padding-top: 3%; margin-right: 3%">
    <img (click)="toggleFullScreenOff()" style="display: flex-end;"src="../../../assets/edit-icons/cancel.svg"  width="27.356" height="27.341" >
  </div>  
</div>

<div [ngClass]="shouldFullScreenShow? 'no-padding': 'padding'">
  <app-resume-one [hidden]="resume !== 1" [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;"(click)="toggleFullScreen()"></app-resume-one>
  <app-resume-two [hidden]="resume !== 2" [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;" (click)="toggleFullScreen()"></app-resume-two>
  <app-resume-three [hidden]="resume !== 3"  [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;" (click)="toggleFullScreen()"></app-resume-three>
  <app-resume-four [hidden]="resume !== 4" [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;" (click)="toggleFullScreen()"></app-resume-four>
  <app-resume-five [hidden]="resume !== 5"  [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;" (click)="toggleFullScreen()"></app-resume-five>
  <app-resume-six [hidden]="resume !== 6"  [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;" (click)="toggleFullScreen()"></app-resume-six>
  <app-resume-seven [hidden]="resume !== 7"  [fullScreen]="shouldFullScreenShow? true: false" style="z-index: 3;" (click)="toggleFullScreen()"></app-resume-seven>

  <div style="display: flex; flex-direction: column;">
    <div [ngClass]="resume === 1? 'filled-circle': 'empty-circle'"><div [hidden]="resume !== 1" class="filled-inner-circle"></div></div>
    <div class="line"></div>
    <div [ngClass]="resume === 2? 'filled-circle': 'empty-circle'"><div [hidden]="resume !== 2" class="filled-inner-circle"></div></div>
    <div class="line"></div>
    <div [ngClass]="resume === 3? 'filled-circle': 'empty-circle'"><div [hidden]="resume !== 3" class="filled-inner-circle"></div></div>
    <div class="line"></div>
    <div [ngClass]="resume === 4? 'filled-circle': 'empty-circle'"><div [hidden]="resume !== 4" class="filled-inner-circle"></div></div>
    <div class="line"></div>
    <div [ngClass]="resume === 5? 'filled-circle': 'empty-circle'"><div [hidden]="resume !== 5" class="filled-inner-circle"></div></div>
    <div [ngClass]="resume > 5? 'filled-circle plus': 'plus'">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.302" height="12.222" viewBox="0 0 12.302 12.222">
        <g id="Group_149" data-name="Group 149" transform="translate(-5979.728 -3150.773)">
          <path id="Path_71" data-name="Path 71" d="M5992.03,3157.854h-5.082V3163h-2.16v-5.141h-5.06v-2.021h5.06v-5.06h2.16v5.06h5.082Z" fill="#fff"/>
        </g>
      </svg>
    </div>
  </div>
</div>
  <div style="width: 100%; display: flex; justify-content: center;">
    <div class="two-buttons-wrapper">
      <button class="two-buttons" mat-button (click)="goBackOneResume()">Prev</button>
      <button mat-button class="next-button"  (click)="showAnotherResume()" class="two-buttons">Next</button>
      <button mat-button class="download-button" (click)="directUserToPaymentOrSuccessPage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="26.342" height="27.928" viewBox="0 0 26.342 27.928">
          <g id="Group_153" data-name="Group 153" transform="translate(-5934.372 -3223.031)">
            <g id="Group_152" data-name="Group 152">
              <path id="Path_72" data-name="Path 72" d="M5953.958,3235.292h-3.92V3224.2a1.17,1.17,0,0,0-1.171-1.17h-2.648a1.171,1.171,0,0,0-1.17,1.17v11.091h-3.921a.692.692,0,0,0-.524,1.142l6.415,7.469a.69.69,0,0,0,1.048,0l6.415-7.469A.691.691,0,0,0,5953.958,3235.292Z"/>
            </g>
            <path id="Path_73" data-name="Path 73" d="M5958.787,3250.959H5936.3a1.928,1.928,0,0,1-1.927-1.927V3242.4h3.414v5.14H5957.3v-5.14h3.414v6.628A1.928,1.928,0,0,1,5958.787,3250.959Z"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
</div>



import { Component, Input, OnInit } from '@angular/core';
import { ResumeData } from 'src/app/interfaces/resume-detail.interface';
import { ResumeDistributorService } from 'src/app/services/resume-distributor.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-resume-three',
  templateUrl: './resume-three.component.html',
  styleUrls: ['./resume-three.component.scss']
})
export class ResumeThreeComponent implements OnInit {

  constructor(private readonly resumeDistributorService: ResumeDistributorService) { }
  resumeData = {} as ResumeData;
  resumeDataSubscription: Subscription;
  @Input() fullScreen: boolean;

  ngOnInit() {
    this.resumeDataSubscription = this.resumeDistributorService.$resumeDetails.subscribe((res) => {
      this.resumeData = res;
    });
  }

  ngOnDestroy() {
    this.resumeDataSubscription.unsubscribe();
  }

}


<div style="max-height: 200px; max-width: 300px;">
  <div [ngClass]="fullScreen? 'no-resume': 'resume'" id="resume">
    
        <div class="header">
          <span class="name">{{resumeData.firstName}} {{resumeData.lastName}}</span>
          <div style="display: flex; align-items: baseline;">
            <img src="../../../../assets/resume-markers/email.png" width="15px" height="10px"/>
            <span class="contact-info-email">{{resumeData.email}} -</span>
            <img style="padding-left: .5rem;" src="../../../../assets/resume-markers/phone.png" width="12px" height="12px"/>
            <span class="contact-info-phone">{{formatPhoneNumberFromString(resumeData.phoneNumber)}}</span>
          </div>
        </div>
 
  
      <div class="section-text-wrapper">
        <img src="../../../../assets/resume-markers/briefcase.png" width="16px" height="15px"/>
        <span class="header-text">Employment:</span>
      </div>
  
      <div *ngFor="let job of resumeData.jobs;">
        <div style="display: flex; padding-top: 1rem">
          <p class="job-bullet" >&#x2022;</p>
          <div class="job-wrapper">
            <span class="job-tenure">{{job.position}} at {{job.companyName}}</span>
            <span class="job-description" >{{job.startDate}} - {{job.endDate}}</span>
            <span class="job-duty">{{job.description}}</span>
          </div>
      </div>
      </div>
  
      <div class="section-text-wrapper">
        <img src="../../../../assets/resume-markers/school.png" width="18px" height="15px"/>
        <span class="header-text">Education:</span>
      </div>
  
      <div class="school-wrapper" *ngFor="let school of resumeData.school;">
        <div style="display: flex; padding-top: 1rem">
          <span style="font-size: 19px; margin-top: -3px;">&#x2022;</span>
          <div style="display: flex; flex-direction: column; margin-left: 1%;">
            <span class="school-name-major">{{school.schoolName}} - {{school.major}}</span>
            <span class="school-years">{{school.startYear}} - {{school.endYear}}</span>
          </div>
        </div>
      </div>
      
      <div class="section-text-wrapper">
        <img  style="align-self: center; margin-top: -6px;" src="../../../../assets/resume-markers/skills.png" width="18px" height="18px"/>
        <span class="header-text">Skills:</span>
      </div>
      <div class="skill-wrapper">
        <div *ngFor="let skill of resumeData.skills; let i = index;">
          <span class="skill">&#x2022; <span style="margin-left: 1%;">{{skill}}</span></span>
        </div>
      </div>
    </div>
  
  </div>
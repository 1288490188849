<div style="display: flex; justify-content: center; flex: 1; flex-wrap: wrap; width: 100%;" *ngIf="shouldNavigationDisplay">
<div [className]="shouldBiggerHeaderShow? 'bigger-marker-wrappers':'marker-wrappers'">
    <!--BUTTON 1-->
    <div [className]="returnButtonStateClass(0)" (click)="navigateTo('/pii')">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.051" height="26.499" viewBox="0 0 22.051 26.499" *ngIf="!shouldPiiBeHighlighted">
          <g id="Group_110" data-name="Group 110" transform="translate(-1385.832 -2644.323)">
          <circle  id="Ellipse_19" data-name="Ellipse 19" cx="6.107" cy="6.107" r="6.107" transform="translate(1391.029 2644.323)"/>
          <path id="Path_54" data-name="Path 54" d="M1403.9,2657.651a2.919,2.919,0,0,0-2.858-.046,8.154,8.154,0,0,1-8.071-.142,2.67,2.67,0,0,0-2.51-.148,8.061,8.061,0,0,0-4.629,7.294h0a6.212,6.212,0,0,0,6.212,6.212h9.627a6.212,6.212,0,0,0,6.212-6.212h0A8.063,8.063,0,0,0,1403.9,2657.651Z"/>
        </g>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="22.051" height="26.499" viewBox="0 0 22.051 26.499" *ngIf="shouldPiiBeHighlighted">
        <g id="Group_136" data-name="Group 136" transform="translate(-961.832 -2644.323)">
          <circle id="Ellipse_25" data-name="Ellipse 25" cx="6.107" cy="6.107" r="6.107" transform="translate(967.029 2644.323)"/>
          <path id="Path_67" data-name="Path 67" d="M979.9,2657.651a2.919,2.919,0,0,0-2.858-.046,8.154,8.154,0,0,1-8.071-.142,2.67,2.67,0,0,0-2.51-.148,8.061,8.061,0,0,0-4.629,7.294h0a6.212,6.212,0,0,0,6.212,6.212h9.627a6.212,6.212,0,0,0,6.212-6.212h0A8.063,8.063,0,0,0,979.9,2657.651Z"/>
        </g>
      </svg>
    </div>
    <div style="width: 10%; line-height: 40px; align-items: center;" >
      <hr [className]="shouldPiiBeHighlighted===true? 'yellow-hr':'normal-hr'">
    </div>

    <div class="svg-wrappers" [className]="returnButtonStateClass(1)" (click)="navigateTo('/work')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24.578" height="22.39" viewBox="0 0 24.578 22.39">
        <path id="Path_51" data-name="Path 51" d="M1492.233,2657.1v-5.5a1.088,1.088,0,0,0-1.089-1.088h-5.258v-1.416a3.711,3.711,0,0,0-3.711-3.711h-4.459a3.71,3.71,0,0,0-3.711,3.71v1.417h-5.261a1.088,1.088,0,0,0-1.089,1.088v5.5h9.41v1.734h-8.949v7.847a1.089,1.089,0,0,0,1.088,1.089h21.48a1.089,1.089,0,0,0,1.088-1.089v-7.847h-8.949V2657.1Zm-10.885,3.817h-2.808v-4.444h2.808Zm-5.039-10.41v-1.416a1.407,1.407,0,0,1,1.407-1.407h4.459a1.407,1.407,0,0,1,1.407,1.406v1.417Z" transform="translate(-1467.655 -2645.377)"/>
      </svg>
    </div>
    <div style="width: 10%; line-height: 40px; align-items: center;">
      <hr [className]="shouldWorkBeHighlighted===true? 'yellow-hr':'normal-hr'">
    </div>

    <div [className]="returnButtonStateClass(2)" (click)="navigateTo('/school')">
      <svg xmlns="http://www.w3.org/2000/svg" width="32.346" height="18.545" viewBox="0 0 32.346 18.545" *ngIf="!shouldSchoolBeHighlighted">
        <g id="Group_105" data-name="Group 105" transform="translate(-1546.857 -2648.299)">
          <g id="Group_104" data-name="Group 104">
            <path id="Path_52" data-name="Path 52" d="M1563.03,2662.312a5.765,5.765,0,0,1-2.157-.41l-7.119-2.882v3.638c0,2.312,4.076,4.186,9.1,4.186s9.1-1.874,9.1-4.186v-3.5l-6.773,2.742A5.771,5.771,0,0,1,1563.03,2662.312Z"/>
            <path id="Path_53" data-name="Path 53" d="M1579.1,2654.5l-14.649-5.93a3.863,3.863,0,0,0-2.838,0l-14.648,5.93a.143.143,0,0,0,0,.277l3.722,1.532v4.836a1.3,1.3,0,0,0-.336,2.027l-.575,2.024a.944.944,0,0,0,1.028,1.133h1a.944.944,0,0,0,1.029-1.133l-.569-2a1.3,1.3,0,0,0-.343-2.059V2656.7l8.8-3.564a.619.619,0,0,1,.465,1.148l-7.76,3.141,8.181,3.281a3.875,3.875,0,0,0,2.838,0l14.649-5.931a.144.144,0,0,0,0-.277Z"/>
          </g>
        </g>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="32.346" height="18.545" viewBox="0 0 32.346 18.545" *ngIf="shouldSchoolBeHighlighted">
        <g id="Group_105" data-name="Group 105" transform="translate(-1546.857 -2648.299)">
          <g id="Group_104" data-name="Group 104" >
            <path id="Path_52" data-name="Path 52" d="M1563.03,2662.312a5.765,5.765,0,0,1-2.157-.41l-7.119-2.882v3.638c0,2.312,4.076,4.186,9.1,4.186s9.1-1.874,9.1-4.186v-3.5l-6.773,2.742A5.771,5.771,0,0,1,1563.03,2662.312Z"/>
            <path id="Path_53" data-name="Path 53" d="M1579.1,2654.5l-14.649-5.93a3.863,3.863,0,0,0-2.838,0l-14.648,5.93a.143.143,0,0,0,0,.277l3.722,1.532v4.836a1.3,1.3,0,0,0-.336,2.027l-.575,2.024a.944.944,0,0,0,1.028,1.133h1a.944.944,0,0,0,1.029-1.133l-.569-2a1.3,1.3,0,0,0-.343-2.059V2656.7l8.8-3.564a.619.619,0,0,1,.465,1.148l-7.76,3.141,8.181,3.281a3.875,3.875,0,0,0,2.838,0l14.649-5.931a.144.144,0,0,0,0-.277Z"/>
          </g>
        </g>
      </svg>
    </div>
    <div style="width: 10%; line-height: 40px; align-items: center;">
      <hr [className]="shouldSchoolBeHighlighted===true? 'yellow-hr':'normal-hr'">
    </div>

    <div class="svg-wrappers" [className]="returnButtonStateClass(3)"  (click)="navigateTo('/skills')">
      <svg xmlns="http://www.w3.org/2000/svg" width="27.843" height="27.843" viewBox="0 0 27.843 27.843">
        <g id="Group_113" data-name="Group 113" transform="translate(-1632.195 -2643.65)">
          <path id="Path_55" data-name="Path 55" d="M1659.455,2655.324l-2.753-.389a.687.687,0,0,1-.556-.483,10.36,10.36,0,0,0-.733-1.764.688.688,0,0,1,.052-.736l1.672-2.223a.679.679,0,0,0-.062-.887l-2.229-2.228a.678.678,0,0,0-.887-.063l-2.223,1.672a.686.686,0,0,1-.735.052,10.371,10.371,0,0,0-1.765-.733.685.685,0,0,1-.483-.556l-.389-2.753a.679.679,0,0,0-.672-.583h-3.151a.678.678,0,0,0-.671.583l-.39,2.753a.685.685,0,0,1-.483.556,10.359,10.359,0,0,0-1.764.733.688.688,0,0,1-.736-.052l-2.223-1.672a.678.678,0,0,0-.887.063l-2.228,2.228a.678.678,0,0,0-.063.887l1.672,2.223a.688.688,0,0,1,.052.736,10.359,10.359,0,0,0-.733,1.764.685.685,0,0,1-.556.483l-2.752.389a.679.679,0,0,0-.584.672v3.151a.679.679,0,0,0,.584.672l2.752.389a.685.685,0,0,1,.556.483,10.4,10.4,0,0,0,.733,1.765.686.686,0,0,1-.052.735l-1.672,2.223a.678.678,0,0,0,.063.887l2.228,2.229a.679.679,0,0,0,.887.062l2.223-1.672a.689.689,0,0,1,.736-.052,10.36,10.36,0,0,0,1.764.733.686.686,0,0,1,.483.556l.39,2.753a.678.678,0,0,0,.671.583h3.151a.679.679,0,0,0,.672-.583l.389-2.753a.686.686,0,0,1,.483-.556,10.372,10.372,0,0,0,1.765-.733.687.687,0,0,1,.735.052l2.223,1.672a.679.679,0,0,0,.887-.062l2.229-2.229a.679.679,0,0,0,.062-.887l-1.672-2.223a.686.686,0,0,1-.052-.735,10.405,10.405,0,0,0,.733-1.765.686.686,0,0,1,.556-.483l2.753-.389a.679.679,0,0,0,.583-.672V2656A.679.679,0,0,0,1659.455,2655.324Zm-13.338,10.072a7.824,7.824,0,1,1,7.824-7.824A7.823,7.823,0,0,1,1646.117,2665.4Z"/>
          <path id="Path_56" data-name="Path 56" d="M1651.772,2655.374l-1.831-1.831-5.232,5.231-2.418-2.417-1.831,1.831,4.234,4.234.015-.015.015.015Z"/>
        </g>
      </svg>
    </div>
    <p *ngIf="shouldBiggerHeaderShow" class="icon-text">Select icon to edit section</p>
</div>
</div>
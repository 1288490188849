

<div class="content-wrapper">
  <div class="form-container">
    <div class="section-title">Work History</div>
    <div [hidden]="shouldJobFormDisplay || shouldAddWorkDescriptionDisplay" class="form-styling">
      <div *ngFor="let item of completedWorkHistory; let i = index;" ngClass="work-history-layout">
        <div class="regular-padding" *ngIf="shouldEditIconsShow.indexOfResumeToEdit !== i" (click)="setEditIcons(i)">
          <div class="job-details">{{item.controls.companyName.value}}</div>
          <div class="job-details">{{item.controls.position.value}}</div>
          <div class="job-details">{{item.controls.startMonth.value}} {{item.controls.startYear.value}} {{getEndDate(item)}}</div>
          <div class="job-details">{{item.controls.description.value}}</div>
        </div>
        
        <div style="height: 100%;" *ngIf="shouldEditIconsShow.shouldEditIconsShow && shouldEditIconsShow.indexOfResumeToEdit === i">
          <button mat-button class="action-buttons" style="background-color: #00A651;" (click)="editJob(i)">
            <img (click)="editJob(i)" src="../../../assets/edit-icons/edit.svg" width="25" height="25" >
          </button>
          <button mat-button class="action-buttons" style="background-color: #1529BE;" (click)="cancelEdit()">
            <img (click)="cancelEdit()" src="../../../assets/edit-icons/cancel.svg" height="20" width="20">
          </button>
          <button mat-button class="action-buttons" style="background-color: #A9343D;" (click)="deleteJob(i)">
            <img (click)="deleteJob(i)" src="../../../assets/edit-icons/delete.svg" height="25" width="25">
          </button>
        </div>
        <hr class="hr">
      </div>
      <button mat-raised-button (click)="addJobForm()" class="add-button" >+ Add Work</button>

    </div>

    <ng-container *ngIf="shouldJobFormDisplay">
      <form [formGroup]="workHistory" class="form-styling">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Company Name: </mat-label>
          <input matInput type="text" formControlName="companyName"  />
        </mat-form-field>
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Position: </mat-label>
          <input matInput type="text" formControlName="position"  />
        </mat-form-field>
        <div class="dates">
        <mat-form-field appearance="fill" class="date" >
          <mat-label>Start month</mat-label>
          <mat-select name="month" formControlName="startMonth" class="default-font-size">
            <mat-option *ngFor="let month of months " [value]="month.value">
              {{month.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="date">
          <mat-label>Start year</mat-label>
          <mat-select name="year" formControlName="startYear" class="default-font-size">
            <mat-option *ngFor="let year of startYears" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
        <mat-checkbox class="form-field default-font-size checkbox-styling" formControlName="currentlyWorksThere">I currently work here
        </mat-checkbox>

        <div class="dates" *ngIf="!workHistory.controls.currentlyWorksThere.value">
        <mat-form-field appearance="fill" class="date">
          <mat-label>End month</mat-label>
          <mat-select name="month" formControlName="endMonth" class="default-font-size">
            <mat-option *ngFor="let month of months" [value]="month.value">
              {{month.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="date">
          <mat-label>End year</mat-label>
          <mat-select name="year" formControlName="endYear" class="default-font-size">
            <mat-option *ngFor="let year of startYears" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </form>
      <p *ngIf="shouldErrorPopupShow" class="error-container">There are errors in your form</p>

    </ng-container>
    <ng-container *ngIf="shouldAddWorkDescriptionDisplay">
      <form [formGroup]="workHistory" class="form-styling">
      <div style="display: flex; flex-direction: column; width: 80%;">
        <div class="job-details">{{workHistory.controls.companyName.value}}</div>
        <div class="job-details">{{workHistory.controls.position.value}}</div>
        <div class="job-details">{{workHistory.controls.startMonth.value}} {{workHistory.controls.startYear.value}} {{getEndDate(workHistory)}}</div>
      </div>
      <mat-form-field appearance="fill" class="input-size"  [hidden]="!shouldAddWorkDescriptionDisplay">
        <mat-label>Description</mat-label>
        <textarea matInput id="textarea" formControlName="description"></textarea>
      </mat-form-field>
    </form>
    <button *ngIf="!shouldRewrittenJobDescriptionDisplay && !isLoading" mat-raised-button (click)="rewriteWithAI()" class="ai-button" >Rewrite with AI</button>

    <div *ngIf="isLoading" style="display: flex">
      <div class="spinner"></div>
      <div class="spinner-label">Generating text with AI</div>
    </div>

    <div class="rewritten-job" *ngIf="shouldRewrittenJobDescriptionDisplay">
      {{rewrittenJobText}}
      <div style="display:flex">
        <button  mat-raised-button (click)="setAITextToDescription()" class="ai-button" >Use This Text</button>
        <button  mat-raised-button (click)="rewriteWithAI()" class="ai-button" >Regenerate</button>
      </div>
    </div>
    <p *ngIf="!shouldRewrittenJobDescriptionDisplay" class="description">In just two to three sentences, describe your main responsibilities and
      duties in this role. We will use AI to rewrite your job description.</p>
    <p *ngIf="shouldErrorPopupShow" class="error-container">There are errors in your form</p>
    <button mat-raised-button (click)="completeJob()" class="add-button" >Done</button>
    </ng-container>
  </div>    
</div>

<div class="two-buttons-wrapper">
  <button class="two-buttons" mat-button (click)="goBack()">Prev</button>
  <button mat-button class="next-button two-buttons" (click)="goNext()">{{getTextForActionButton()}}</button>
</div>

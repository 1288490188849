import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SchoolModule } from './components/school/school.module';
import { SkillsModule } from './components/skills/skills.module';
import { WorkModule } from './components/work/work.module';
import { PiiModule } from './components/pii/pii.module';
import { HomepageModule } from './components/homepage/homepage.module';
import { ResumePreviewModule } from './components/resume-preview/resume-preview.module';
import { GlobalModule } from './global/global.module';
import { SuccessModule } from './components/success/success.module';
import {BlogModule} from './components/blog/blog.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ReactiveFormsModule,
    HomepageModule,
    SchoolModule,
    SkillsModule,
    WorkModule,
    PiiModule,
    BrowserModule,
    HammerModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDividerModule,
    ResumePreviewModule,
    GlobalModule ,
    SuccessModule,
    BlogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
